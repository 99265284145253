const home = (color) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 11.9127C3 9.99127 3 9.03056 3.40674 8.19893C3.81348 7.36729 4.57181 6.77748 6.08847 5.59785L7.08848 4.82007C9.44787 2.98499 10.6276 2.06745 12 2.06745C13.3724 2.06745 14.5521 2.98499 16.9115 4.82008L17.9115 5.59785C19.4282 6.77748 20.1865 7.36729 20.5933 8.19893C21 9.03056 21 9.99127 21 11.9127V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V11.9127Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
        <path d="M9 17C9 16.0681 9 15.6022 9.15224 15.2346C9.35523 14.7446 9.74458 14.3552 10.2346 14.1522C10.6022 14 11.0681 14 12 14V14C12.9319 14 13.3978 14 13.7654 14.1522C14.2554 14.3552 14.6448 14.7446 14.8478 15.2346C15 15.6022 15 16.0681 15 17V22H9V17Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
    </svg>
}

const settings = (color) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12.8794V11.1194C2 10.0794 2.85 9.21945 3.9 9.21945C5.71 9.21945 6.45 7.93945 5.54 6.36945C5.02 5.46945 5.33 4.29945 6.24 3.77945L7.97 2.78945C8.76 2.31945 9.78 2.59945 10.25 3.38945L10.36 3.57945C11.26 5.14945 12.74 5.14945 13.65 3.57945L13.76 3.38945C14.23 2.59945 15.25 2.31945 16.04 2.78945L17.77 3.77945C18.68 4.29945 18.99 5.46945 18.47 6.36945C17.56 7.93945 18.3 9.21945 20.11 9.21945C21.15 9.21945 22.01 10.0694 22.01 11.1194V12.8794C22.01 13.9194 21.16 14.7794 20.11 14.7794C18.3 14.7794 17.56 16.0594 18.47 17.6294C18.99 18.5394 18.68 19.6994 17.77 20.2194L16.04 21.2094C15.25 21.6794 14.23 21.3994 13.76 20.6094L13.65 20.4194C12.75 18.8494 11.27 18.8494 10.36 20.4194L10.25 20.6094C9.78 21.3994 8.76 21.6794 7.97 21.2094L6.24 20.2194C5.33 19.6994 5.02 18.5294 5.54 17.6294C6.45 16.0594 5.71 14.7794 3.9 14.7794C2.85 14.7794 2 13.9194 2 12.8794Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
}

const web = (color) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_20_218)">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.0001 3H9.0001C7.0501 8.84 7.0501 15.16 9.0001 21H8.0001" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 8.99961C8.84 7.04961 15.16 7.04961 21 8.99961" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_20_218">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>    
}

const alert = (color, count) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8V13M11.9941 16H12.0031M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
}

const message = (color, count) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5968 11.0758H15.6049M11.9959 11.0758H12.004M8.39506 11.0758H8.40314M8.85 18.2542H8.4C4.8 18.2542 3 17.3569 3 12.8704V8.38385C3 4.79462 4.8 3 8.4 3H15.6C19.2 3 21 4.79462 21 8.38385V12.8704C21 16.4596 19.2 18.2542 15.6 18.2542H15.15C14.871 18.2542 14.601 18.3888 14.43 18.6132L13.08 20.4078C12.486 21.1974 11.514 21.1974 10.92 20.4078L9.57 18.6132C9.426 18.4158 9.093 18.2542 8.85 18.2542Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
}

const IconSVG = ({icon, color}) => {
    switch (icon) {
        case "Home":
            return home(color)
        case "Settings":
            return settings(color)
        case "Web":
            return web(color)
        case "Alert":
            return alert(color)
        case "Message":
            return message(color)
        default:
            return null
    }
}

export default IconSVG