import { useState } from "react"
import { gatewayURL } from "../../../store/api"
import "../style.css"
import Modal from "../../Modal"
import ShopConnectForm from "../ShopConnectForm"

const StartShopView = ({data, button, msg}) => {
    const [m, setM] = useState(false)

    const handlerClose = () => {
        setM(false)
    }

    return (
        <>
            <div className="shop-view">
                <div className="logo-view image">
                    <img src={`${gatewayURL}/api/v1/images/settings/shops/${data.logo}`} alt={data.title} />
                </div>
                <div className="shop-view-actives">
                    {data.active ? 
                        <button className="button" 
                                onClick={() => setM(true)}
                                style={{width: '100%'}}>{button}</button> : 
                        <div style={{textAlign: 'center'}}>{msg}</div>}
                </div>
            </div>
            {m ? <Modal close={handlerClose} body={<ShopConnectForm shop={data} />} /> : null}
        </>
    )
}

export default StartShopView