import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPricerProductParam, setPricerParamOZ, setPricerParamWB, setPricerParamYM, updatePricerProductParams } from "../../../../../store/SettingsStore/services"
import PricerDetails from "../../../../../components/PricerDetails"

const PricerProduct = ({pricer, product}) => {

    const dispatch = useDispatch()

    const pricerParamProductWB = useSelector((state) => state.services.pricerParamProductWB)
    const pricerParamProductOZ = useSelector((state) => state.services.pricerParamProductOZ)
    const pricerParamProductYM = useSelector((state) => state.services.pricerParamProductYM)

    const [p, setP] = useState(null)
    const [t, setT] = useState(null)
    const [a, setA] = useState(false)

    useEffect(() => {
        return () => {
            dispatch(setPricerParamOZ(null))
            dispatch(setPricerParamWB(null))
            dispatch(setPricerParamYM(null))
        }
    }, [dispatch])

    useEffect(() => {
        switch (pricer.shopCode) {
            case "wb":
                setT("WB")
                setP(pricerParamProductWB)
                break
            case "oz":
                setT("OZON")
                setP(pricerParamProductOZ)
                break
            case "ym":
                setT("YM")
                setP(pricerParamProductYM)
                break
            default:
                break
        }
    }, [pricer, product, pricerParamProductOZ, pricerParamProductWB, pricerParamProductYM])
    
    useEffect(() => {
        dispatch(getPricerProductParam(pricer.shopCode, product.ID))
    }, [dispatch, pricer.shopCode, product.ID])

    useEffect(() => {
        if (!a) {
            if (pricer.limit - (pricer.used ?? 0) > 0) {
                setA(true)
            }

            if (p && p.active) {
                setA(true)
            }
        }
    }, [pricer, a, product, p])

    const handlerSend = (data) => {
        data.productID = product.ID
        data.shopCode = pricer.shopCode
        dispatch(updatePricerProductParams(data, pricer.shopCode, product.ID))
    }
    
    return (
        <div className="simple-block service">
            <h3>Прайсер {t}</h3>
            <PricerDetails param={p} handler={handlerSend} access={a} />
        </div>
    )
}

export default PricerProduct