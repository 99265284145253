import Loop from "../../assets/images/preloader.svg"

const Preloader = () => {
    return (
        <div>
            <img src={Loop} alt="Preloader" width="40"/>
        </div>
    )
}

export default Preloader