import { createSlice } from "@reduxjs/toolkit"
import { gatewayURL } from "../api"
import axios from "axios"

export const search = createSlice({
    name: 'search',
    initialState: {
        request: "",
        data: []
    },
    reducers: {
        setSearchData: (state, action) => {
            state.data = action.payload
        },
        setSearchRequest: (state, action) => {
            state.request = action.payload
        }
    }
})

export const { setSearchData, setSearchRequest } = search.actions

export const getSearch = () => (dispatch, getState) => {
    let token = getState().auth.token
    let req = getState().search.request
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/search/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: req
    };

    axios.request(config)
        .then((response) => {
            let res = response.data.result.products

            if (typeof res !== "undefined") {
                dispatch(setSearchData(res))
            } else {
                dispatch(setSearchData([]))
            }
            
        })
        .catch((error) => {
            console.log(error.data)
            dispatch(setSearchData([]))
        });
}

export default search.reducer