import { useEffect, useState } from "react"

const FromToSelector = ({from, to, handler}) => {

    const [f, setF] = useState("")
    const [t, setT] = useState("")

    useEffect(() => {
        if (from) {
            setF(from)
        }

        if (to) {
            setT(to)
        }
    }, [from, to])

    const handlerGet = () => {
        handler(f, t)
    }

    return (
        <div className="from-to">
            <input className="date-field" type="date" name="from" value={f} onChange={e => setF(e.target.value)} /> <input className="date-field" type="date" name="to" value={t} onChange={e => setT(e.target.value)} />
            <button className="button" onClick={handlerGet}>Применить</button>
        </div>
    )
}

export default FromToSelector