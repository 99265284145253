import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getSystemShop } from "../../../../store/ShopsStore/systemShops"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import FilterProducts from "../../../../components/Filters/FilterProducts"
import SortingProducts from "../../../../components/Filters/SortingProducts"
import ViewTypeProducts from "../../../../components/Filters/ViewTypeProducts"
import ProductsShopList from "./ProductsShopList"
import Pagination from "../../../../components/Filters/Pagination"
import { getBrands } from "../../../../store/SettingsStore/brands"
import FilterProductsBrand from "../../../../components/Filters/FilterProducts/brandFilter"
import { setProductsParam } from "../../../../store/ShopsStore/productsParams"

const ProductsShopPage = () => {

    let {code} = useParams()

    const dispatch = useDispatch()

    const brands = useSelector((state) => state.brands.list)

    const systemShops = useSelector((state) => state.systemShops.list)
    const shop = useSelector((state) => state.systemShops.shop)

    useEffect(() => {
        if (shop === null || shop.code !== code) {
            dispatch(getSystemShop(code))
        }
        if (brands.length === 0) {
            dispatch(getBrands())
        }
    }, [shop, code, dispatch, systemShops, brands])

    useEffect(() => {
        if (shop !== null) {
            dispatch(setPageTitleTitle(shop.title))
            dispatch(setPageTitleSubTitle('Товары'))
            dispatch(setPageTitleBack({name: 'Назад', link: `/app/${code}`}))
        }
    }, [dispatch, shop, code])

    console.log(brands)

    return (
        <>
        <div style={{display: "flex"}}>
            <div className="ws-sidebar">
                <FilterProducts code={code}/>
                <FilterProductsBrand code={code} brands={brands} />
                <div style={{marginTop: 24}}>
                    <button className="button" onClick={() => {dispatch(setProductsParam(code, 'load', true))}}>Применить</button>
                </div>
            </div>
            <div>
                <div style={{display: "flex", marginBottom: 24}}>
                    <SortingProducts code={code}/>
                    <ViewTypeProducts code={code}/>
                </div>
                <ProductsShopList code={code}/>
                <Pagination code={code}/>
            </div>
        </div>
        </>
    )
}

export default ProductsShopPage