import { useEffect, useState } from "react"
import Switcher from "../Switcher"
import { useDispatch } from "react-redux"
import { sendCounterProduct } from "../../store/SettingsStore/services"

const shops = [
    {
        code: "wb",
        title: "WB"
    },
    {
        code: "oz",
        title: "OZON"
    },
    {
        code: "ym",
        title: "YM"
    }
]

const CounterDetails = ({data, productID}) => {

    console.log(data)

    const dispatch = useDispatch()

    const [c, setC] = useState(0)
    const [u, setU] = useState(null)
    const [s, setS] = useState([])

    useEffect(() => {
        setU(uuidv4())

        if (data) {
            setC(data.productCount)
        }

        let tmp = []

        shops.forEach(shop => {
            let active = false

            if (data) {
                const find = data.counterShopParams.findIndex(e => e.shopCode === shop.code)
                if (find > -1) {
                    active = data.counterShopParams[find].active
                }
            }

            tmp.push({code: shop.code, title: shop.title, active: active})
        })

        setS(tmp)
    }, [data])

    const handlerSave = () => {
        let sd = []

        s.forEach(el => {
            sd.push({
                code: el.code,
                active: el.active
            })
        })
        dispatch(sendCounterProduct(
            {
                productID: productID,
                count: parseInt(c),
                shopsData: sd
            }
        ))
    }

    const handlerSwitch = (code, active) => {
        let tmp = []

        s.forEach(s => {
            if (s.code === code) {
                tmp.push({code: s.code, title: s.title, active: active})
            } else {
                tmp.push({code: s.code, title: s.title, active: s.active})
            }
        })

        setS(tmp)
    }

    return (
        <>
            <div className="form-row mb-24">
                <label htmlFor={`target-${u}`}>
                    <div className="form-label">Остаток на складе</div>
                    <input id={`target-${u}`}
                            name="target"
                            type="number"
                            className="text-field"
                            required
                            value={c}
                            onChange={e => setC(e.target.value)} />
                </label>
            </div>
            <div className="mb-24">
                {s.map((e, i) => <ShopParam key={i} shop={e} handler={handlerSwitch} />)}
            </div>
            <div className="form-row">
                <button onClick={handlerSave} className="button">Сохранить</button>
            </div>
        </>
    )
}

export default CounterDetails

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const ShopParam = ({shop, handler}) => {
    
    const handlerSwitch = () => {
        handler(shop.code, !shop.active)
    }

    return (
        <div className="mb-12">
            <Switcher active={shop.active} handler={handlerSwitch} label={shop.title} />
        </div>
    )
}