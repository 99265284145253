import "./style.css"

const Informator = ({message, type}) => {
    return (
        <div className={`informator ${type}`}>
            {message}
        </div>
    )
}

export default Informator