import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { addCompanyBrand, serachBrands, setSearchFindBrands } from "../../../../../store/SettingsStore/brands"
import SearchRes from "../Search/SearchRes"

const AddBrand = () => {

    const dispatch = useDispatch()
    
    const [n, setN] = useState("")
    const [ne, setNE] = useState(false)
    const [d, setD] = useState("")
    const [l, setL] = useState("")
    const [u, setU] = useState("")

    useEffect(() => {
        return () => {
            dispatch(setSearchFindBrands([]))
        }
    }, [dispatch])

    useEffect(() => {
        if (n.length > 2) {
            dispatch(serachBrands(
                {
                    name: n,
                    all: true
                }
            ))
        } else {
            dispatch(setSearchFindBrands([]))
        }
    }, [n, dispatch])

    const handlerSave = () => {
        if (n.trim() === "") {
            setNE(true)
        } else {
            dispatch(addCompanyBrand(
                {
                    name: n,
                    description: d,
                    logo: l,
                    url: u
                }
            ))
        }
    }

    const handlerChange = (e) => {
        setN(e.target.value)
        setNE(false)
    }

    return (
        <div>
            <h3>Добавить бренд</h3>
            <div className="flex">
                <div style={{flex: '0 0 40%', marginRight: 24}}>
                    <div className="form-row">
                        <label htmlFor="name">
                            <div className="form-label">Название</div>
                            <input id="name"
                                    name="name"
                                    type="text"
                                    className={`text-field ${ne ? "error" : ""}`}
                                    required
                                    value={n}
                                    onChange={e => handlerChange(e)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <label htmlFor="desc">
                            <div className="form-label">Описание</div>
                            <input id="desc"
                                    name="desc"
                                    type="text"
                                    className="text-field"
                                    value={d}
                                    onChange={e => setD(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <label htmlFor="logo">
                            <div className="form-label">Логотип</div>
                            <input id="logo"
                                    name="logo"
                                    type="text"
                                    className="text-field"
                                    value={l}
                                    onChange={e => setL(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <label htmlFor="url">
                            <div className="form-label">Адрес сайта</div>
                            <input id="url"
                                    name="url"
                                    type="text"
                                    className="text-field"
                                    value={u}
                                    onChange={e => setU(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <button className="button" onClick={handlerSave}>Сохранить</button>
                    </div>
                </div>
                <div>
                    <SearchRes />
                </div>
            </div>
        </div>
    )
}

export default AddBrand