import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { setProductsParam } from '../ShopsStore/productsParams';
axios.defaults.withCredentials = true;

export const prods = createSlice({
    name: 'prods',
    initialState: {
        list: []
    },
    reducers: {
        setProdsList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setProdsList } = prods.actions

export const getProds = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.products !== 'undefined') {
                dispatch(setProdsList(response.data.result.products))
            } else {
                dispatch(setProdsList([]))
            }

            dispatch(setProductsParam('prods', 'pagination', response.data.result.pagination))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default prods.reducer