import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages'

export const companyShops = createSlice({
    name: 'companyShops',
    initialState: {
        list: null,
        loadList: false,
        setCompany: false
    },
    reducers: {
        setShopsList: (state, action) => {
            state.list = action.payload
        },
        setLoadListCom: (state, action) => {
            state.loadList = action.payload
        },
        setSetCompanyShop: (state, action) => {
            state.setCompany = action.payload
        }
    }
})

export const { setShopsList, setLoadListCom, setSetCompanyShop } = companyShops.actions

export const getCompanyShops = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.shops !== 'undefined') {
                dispatch(setShopsList(response.data.result.shops))
            }

            dispatch(setLoadListCom(true))
        })
        .catch((error) => {
            dispatch(setLoadListCom(true))

            let err = "Ошибка"

            if (typeof error.response.data.result !== 'undefined') {
                err = error.response.data.result
            }

            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: err, 
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const setCompanyShops = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(getCompanyShops())
        })
        .catch((error) => {
            let err = "Ошибка добавления"

            if (typeof error.response.data.result !== 'undefined') {
                err = error.response.data.result
            }

            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: err, 
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default companyShops.reducer