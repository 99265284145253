import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"

const FilterPurchase = ({code}) => {

    const dispatch = useDispatch()

    const pfPurchase = useSelector((state) => state.productsParams[code].pfPurchase)
    
    return (
        <div className="blocker" style={{marginBottom: 24}}>
            <div>
                <label htmlFor="activePurchaseCheck">
                    <input 
                        id="activePurchaseCheck" 
                        checked={pfPurchase}
                        onChange={() => dispatch(setProductsParam(code, 'pfPurchase', !pfPurchase))} 
                        type="checkbox"/>
                    Без себестоимости
                </label>
            </div>
        </div>
    )
}

export default FilterPurchase