import Cross from "../../assets/images/cross-w.svg"

const ButtonDelete = ({handler}) => {
    return (
        <button className="button-delete" 
                style={{backgroundImage: `url(${Cross})`}}
                onClick={handler} />
    )
}

export default ButtonDelete