import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../../store/NavStore/title"
import FilterProducts from "../../../../../components/Filters/FilterProducts"
import SortingProducts from "../../../../../components/Filters/SortingProducts"
import ViewTypeProducts from "../../../../../components/Filters/ViewTypeProducts"
import Pagination from "../../../../../components/Filters/Pagination"
import ProdsList from "./ProdsList"
import { getCompanyShops } from "../../../../../store/ShopsStore/companyShops"
import Preloader from "../../../../../components/Preloader"
import { getOzonUnsortedCount } from "../../../../../store/ShopsStore/oz"
import { useNavigate } from "react-router-dom"
import { getWBUnsortedCount } from "../../../../../store/ShopsStore/wb"
import { getBrands } from "../../../../../store/SettingsStore/brands"
import FilterProductsBrand from "../../../../../components/Filters/FilterProducts/brandFilter"
import { setProductsParam } from "../../../../../store/ShopsStore/productsParams"
import FilterPricer from "../../../../../components/Filters/FilterProducts/pricerFilter"
import { getYmUnsortedCount } from "../../../../../store/ShopsStore/ym"
import SearchProducts from "../ProductSearch"
import FilterPurchase from "../../../../../components/Filters/FilterProducts/purchaseFilter"

const ProductsSettingsList = () => {

    const dispatch = useDispatch()

    const brands = useSelector((state) => state.brands.list)

    const code = "prods"

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Товары'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings`}))

        if (brands.length === 0) {
            dispatch(getBrands())
        }
    }, [dispatch, brands])

    return (
        <>
            <div style={{display: "flex"}}>
                <div className="ws-sidebar">
                    <FilterProducts code={code}/>
                    <FilterPricer code={code}/>
                    <FilterPurchase code={code}/>
                    <FilterProductsBrand code={code} brands={brands} />
                    <div style={{marginTop: 24}}>
                        <button className="button" onClick={() => {dispatch(setProductsParam(code, 'load', true))}}>Применить</button>
                    </div>
                    <SearchProducts />
                    <Unsorted />
                </div>
                <div>
                    <div style={{display: "flex", marginBottom: 24}}>
                        <SortingProducts code={code}/>
                        <ViewTypeProducts code={code}/>
                    </div>
                    <ProdsList code={code}/>
                    <Pagination code={code}/>
                </div>
            </div>
        </>
    )
}

export default ProductsSettingsList

const Unsorted = () => {

    const dispatch = useDispatch()

    const companyShops = useSelector((state) => state.companyShops.list)

    useEffect(() => {
        if (companyShops == null) {
            dispatch(getCompanyShops())
        }
    }, [dispatch, companyShops])

    if (companyShops == null) {
        return (
            <Preloader />
        )
    }

    return (
        <div className="simple-block" style={{marginTop: 24}}>
            <h4>Неотсортированные</h4>
            {companyShops.map(e => <UnsortedShop key={e.ID} shop={e.shop} />)}
        </div>
    )
}

const UnsortedShop = ({shop}) => {

    const count = useSelector((state) => state[shop.code].unsortedCount)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        switch(shop.code) {
            case "oz":
                dispatch(getOzonUnsortedCount())
                break
            case "wb":
                dispatch(getWBUnsortedCount())
                break
            case "ym":
                dispatch(getYmUnsortedCount())
                break
            default:
                break
        }
    }, [dispatch, shop.code])


    if (count === null || count === 0 || typeof count === 'undefined') {
        return (
            <div>
                {shop.title}
            </div>
        )
    }

    return (
        <div>
            {shop.title} - <span className="link" onClick={() => navigate(`linker/${shop.code}`)}>{count}</span>
        </div>
    )
}