import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { getCompanyTaxes, getTaxes, setCompanyTaxesStore } from "../../../../store/SettingsStore/tax"
import Preloader from "../../../../components/Preloader"

const TaxSettings = () => {

    const dispatch = useDispatch()

    const tax = useSelector((state) => state.tax)

    const years = [2023, 2024]

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Налоги'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getTaxes())
        dispatch(getCompanyTaxes())
    }, [dispatch])

    if (tax.taxes.length === 0) {
        return (
            <Preloader />
        )
    }

    return (
        <div>
            {years.map((e, i) => <TaxRow key={i} year={e} />)}
        </div>
    )
}

export default TaxSettings

const TaxRow = ({year}) => {

    const dispatch = useDispatch()

    const taxes = useSelector((state) => state.tax.taxes)
    const companyTaxes = useSelector((state) => state.tax.companyTaxes)

    const [t, setT] = useState(0)

    const handleChange = (value) => {
        setT(value)
    }

    const handlerSubmit = () => {
        dispatch(setCompanyTaxesStore({year: year, taxID: parseInt(t)}))
    }

    useEffect(() => {
        const ind = companyTaxes.findIndex(e => e.year === year)
        if (ind > -1) {
            setT(companyTaxes[ind].tax.ID)
        }
    }, [year, taxes, companyTaxes])

    return (
        <div>
            <h3 style={{marginBottom: 6}}>{year}</h3>
            <div className="flex">
                <select style={{marginRight: 20}} className="select-field" value={t} onChange={e => handleChange(e.target.value)}>
                    <option value={0}>Выбрать</option>
                    {taxes.map(e => <option key={e.ID} value={e.ID}>{e.title}</option>)}
                </select>
                <button disabled={t === 0} className="button" onClick={handlerSubmit}>Сохранить</button>
            </div>
        </div>
    )
}