import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"

const FilterPricer = ({code}) => {

    const dispatch = useDispatch()

    const pfPricer = useSelector((state) => state.productsParams[code].pfPricer)
    
    return (
        <div className="blocker" style={{marginBottom: 24}}>
            <div>
                <label htmlFor="activePricerCheck">
                    <input 
                        id="activePricerCheck" 
                        checked={pfPricer}
                        onChange={() => dispatch(setProductsParam(code, 'pfPricer', !pfPricer))} 
                        type="checkbox"/>
                    С прайсером
                </label>
            </div>
        </div>
    )
}

export default FilterPricer