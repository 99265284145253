import { configureStore } from '@reduxjs/toolkit'

import auth from './AuthStore/auth'
import systemShops from './ShopsStore/systemShops'
import companyShops from './ShopsStore/companyShops'
import oz from './ShopsStore/oz'
import productsParams from './ShopsStore/productsParams'
import wb from './ShopsStore/wb'
import pageTitle from './NavStore/title'
import margin from './SettingsStore/margin'
import tax from './SettingsStore/tax'
import brands from './SettingsStore/brands'
import brand from './SettingsStore/brand'
import prods from './SettingsStore/prods'
import prod from './SettingsStore/prod'
import linker from './SettingsStore/linker'
import saleDetails from './ShopsStore/saleDetails'
import productSales from './ShopsStore/productSales'
import mainGraphic from './ShopsStore/mainGraphic'
import messages from './System/messages'
import donutGraphic from './ShopsStore/donutGraphic'
import weekGraphic from './ShopsStore/weekGraphic'
import services from './SettingsStore/services'
import ym from './ShopsStore/ym'
import search from './SettingsStore/search'
import profile from './SettingsStore/profile'

export default configureStore({
	reducer: {
        auth,
        systemShops,
        companyShops,
        oz,
        wb,
        ym,
        productsParams,
        pageTitle,
        margin,
        tax,
        brands,
        brand,
        prods,
        prod,
        linker,
        saleDetails,
        productSales,
        mainGraphic,
        messages,
        donutGraphic,
        weekGraphic,
        services,
        search,
        profile
    },
})