import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import MarginData from "../../../../components/MarginData"
import { deleteMargin, getMargin, getMargins, setMargin, updateMargin } from "../../../../store/SettingsStore/margin"

const MarginSettings = () => {
    const dispatch = useDispatch()

    const margin = useSelector((state) => state.margin)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Маржа'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getMargin())
        dispatch(getMargins())
    }, [dispatch])

    const saveHandler = (data) => {
        let send = {
            min: parseInt(data.min),
            mid: parseInt(data.mid),
            max: parseInt(data.max)
        }
        if (!data.upd) {
            dispatch(setMargin(send))
        } else {
            send.marginID = data.ID
            dispatch(updateMargin(send))
        }
    }

    const removeHandler = (data) => {
        dispatch(deleteMargin(
            {
                marginID: data
            }
        ))
    }

    return <MarginData margin={margin} save={saveHandler} remove={removeHandler} removeRes={margin.removeRes} />
}

export default MarginSettings