import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductsSales } from "../../../../../store/ShopsStore/productSales"
import { useParams } from "react-router-dom"
import Preloader from "../../../../../components/Preloader"
import FromToSelector from "../../../../../components/Filters/FromToSelector"
import SalesListComp from "../../../../../components/SalesList/salesList"
import { getSaleDetails, setSaleData, setSaleDetails } from "../../../../../store/ShopsStore/saleDetails"

const ProductSales = () => {
    const {code, id} = useParams()

    const dispatch = useDispatch()

    const [ft, setFT] = useState("")
    const [tt, setTT] = useState("")

    useEffect(() => {
        if (ft === "" || tt === "") {
            const today = new Date()
            const from = new Date()
            const to = new Date()
            to.setDate(today.getDate() + 1)
            from.setDate(to.getDate() - 14)
            const f = from.toISOString().split('T')[0]
            const t = to.toISOString().split('T')[0]
            setFT(f)
            setTT(t)
        }
    }, [ft, tt])

    useEffect(() => {
        if (ft !== "" && tt !== "") {
            dispatch(getProductsSales(code, id, ft, tt))
        }
    }, [dispatch, code, id, ft, tt])

    const handlerChange = (f, t) => {
        setFT(f)
        setTT(t)
    }

    return (
        <div>
            <FromToSelector from={ft} to={tt} handler={handlerChange} />
            <List />
        </div>
    )
}

export default ProductSales

const List = () => {

    const {code} = useParams()

    const dispatch = useDispatch()

    const sales = useSelector((state) => state.productSales.sales)

    const handlerOpen = (data) => {
        dispatch(setSaleData(data))
        dispatch(getSaleDetails(code, data))
    }

    const handlerClose = () => {
        dispatch(setSaleData(null))
        dispatch(setSaleDetails(null))
    }

    if (sales === null) {
        return (
            <Preloader />
        )
    }

    if (typeof sales === "undefined") {
        return (
            <div>Нет данных</div>
        )
    }

    return (
        <SalesListComp sales={sales} open={handlerOpen} close={handlerClose} />
    )
}