import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Preloader from "../../../../components/Preloader"
import { getSystemShop } from "../../../../store/ShopsStore/systemShops"
import MainShopView from "./MainShopView"
import { getWeekGraphData, setWeekGraphData } from "../../../../store/ShopsStore/weekGraphic"

const FullShopPage = () => {
    let {code} = useParams()

    const dispatch = useDispatch()

    const [view, setView] = useState(null)

    const systemShops = useSelector((state) => state.systemShops.list)
    const companyShops = useSelector((state) => state.companyShops.list)
    const shop = useSelector((state) => state.systemShops.shop)

    useEffect(() => {
        if (shop === null || shop.code !== code) {
            dispatch(getSystemShop(code))
        }
    }, [shop, code, dispatch, systemShops])

    useEffect(() => {
        dispatch(getWeekGraphData(code))
        dispatch(setWeekGraphData(null))
    }, [dispatch, code])

    useEffect(() => {
        if (shop !== null && companyShops !== null) {
            let findShop = companyShops.find(s => s.shop.ID === shop.ID)

            if (findShop.data) {
                if (!findShop.data.auth) {
                    setView("auth")
                } else if (!shop.active) {
                    setView("active")
                } else {
                    setView("view")
                }
            }
        }
    }, [shop, companyShops])

    if (view === null) {
        return (
            <Preloader />
        )
    }

    return (
        <>
        {view === "view" ? <MainShopView shop={shop} /> : null}
        </>
    )
}

export default FullShopPage