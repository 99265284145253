import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"

const FilterProducts = ({code}) => {

    const dispatch = useDispatch()

    const pfActive = useSelector((state) => state.productsParams[code].pfActive)
    
    return (
        <div className="blocker" style={{marginBottom: 24}}>
            <div>
                <label htmlFor="activeCheck">
                    <input 
                        id="activeCheck" 
                        checked={pfActive}
                        onChange={() => dispatch(setProductsParam(code, 'pfActive', !pfActive))} 
                        type="checkbox"/>
                    Только активные
                </label>
            </div>
        </div>
    )
}

export default FilterProducts