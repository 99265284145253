import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { setProductsParam } from './productsParams';
axios.defaults.withCredentials = true;

export const wb = createSlice({
    name: 'wb',
    initialState: {
        widgetSales: null,
        widgetCount: null,
        products: null,
        product: null,
        unsortedCount: null,
        unsortedList: null,
        sales: null
    },
    reducers: {
        setWBWidgetSales: (state, action) => {
            state.widgetSales = action.payload
        },
        setWBWidgetCount: (state, action) => {
            state.widgetCount = action.payload
        },
        setWBProducts: (state, action) => {
            state.products = action.payload
        },
        setWBProduct: (state, action) => {
            state.product = action.payload
        },
        setWBUnsortedCount: (state, action) => {
            state.unsortedCount = action.payload
        },
        setWBUnsortedList: (state, action) => {
            state.unsortedList = action.payload
        },
        setWBSales: (state, action) => {
            state.sales = action.payload
        }
    }
})

export const { setWBWidgetSales, setWBWidgetCount, setWBProducts, setWBProduct, setWBUnsortedCount, setWBUnsortedList, setWBSales } = wb.actions

export const getWBWidgetSales= () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/wb/widgets/sales`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setWBWidgetSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWBWidgetCount = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/wb/widgets/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setWBWidgetCount(response.data.result.count))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWBProducts = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/wb/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(setWBProducts(response.data.result.products))
            dispatch(setProductsParam('wb', 'pagination', response.data.result.pagination))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWBProduct = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/wb/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setWBProduct(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWBUnsortedCount = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/wb/unsorted/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setWBUnsortedCount(response.data.result.count))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWBUnsortedList = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/wb/unsorted/list`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.products!== 'undefined') {
                dispatch(setWBUnsortedList(response.data.result.products))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWBSales = (from, to) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/wb/sales`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from,
            to
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setWBSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default wb.reducer