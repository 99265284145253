import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useNavigate } from "react-router-dom"
import { getServices } from "../../../../store/SettingsStore/services"
import User from "../../../../assets/images/user.svg"

const ServicesSettings = () => {
    const dispatch = useDispatch()

    const services = useSelector((state) => state.services.list)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Сервисы'))
        dispatch(setPageTitleBack({name: 'Настройки', link: `/app/settings`}))

        dispatch(getServices())
    }, [dispatch])

    return (
        <div className="flex" style={{flexWrap: 'wrap', margin: -10}}>
            {services.map((e, i) => <ServiceBlock key={i} data={e.service} />)}
        </div>
    )
}
 
export default ServicesSettings

const ServiceBlock = ({data}) => {

    const [icon, setIcon] = useState(User)

    useEffect(() => {
        switch(data.code) {
            case "pwb":
                setIcon(User)
                break
            case "poz":
                setIcon(User)
                break
            case "awb":
                setIcon(User)
                break
            case "aoz":
                setIcon(User)
                break
            default:
                setIcon(User)
                break
        }
    }, [data.code])

    const navigate = useNavigate()

    if (data.active) {
        return (
            <div className="settings-block linked"
                onClick={() => navigate(`${data.ID}`)}>
                <div style={{backgroundImage: `url(${icon})`}}>
                    <h3>{data.title}</h3>
                </div>
            </div>
        )
    }

    return (
        <div className="settings-block">
            <div style={{backgroundImage: `url(${icon})`}}>
                <h3>{data.title}</h3>
            </div>
        </div>
    )
}