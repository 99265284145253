import { useState } from "react"
import { useSelector } from "react-redux"
import UserMenu from "./menu"

const UserHeaderWrapper = () => {

    const user = useSelector((state) => state.auth.user)

    const [menu, setMenu] = useState(false)

    const handlerCloseMenu = () => {
        setMenu(false)
    }

    return (
        <div className="user-block-menu">
            <div className="user-block" onClick={() => setMenu(!menu)}>
                <div className="user-icon">
                    <span className="user-icon-letter">{user.name[0]}</span>
                </div>
                <div>
                    {user.name}
                </div>
            </div>
            {menu ? <UserMenu close={handlerCloseMenu} /> : null}
        </div>
    )
}

export default UserHeaderWrapper