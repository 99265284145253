import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setCompanyBrand } from "../../../../../store/SettingsStore/brands"
import Label from "../../../../../assets/images/label.svg"

const SearchRes = () => {
    const findBrands = useSelector((state) => state.brands.search)

    if (findBrands.length === 0) {
        return (
            <div></div>
        )
    }

    return (
        <>
        <table className="table-params">
            <thead>
                <tr>
                    <th colSpan={2}>Бренд</th>
                    <th>Описание</th>
                    <th>Сайт бренда</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {findBrands.map((e, i) => <SearchRow key={i} data={e} />)}
            </tbody>
        </table>
        </>
    )
}

export default SearchRes

const SearchRow = ({data}) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handlerAdd = () => {
        dispatch(setCompanyBrand(data))
    }

    return (
        <tr>
            <td><img style={{maxHeight: 30}} src={data.logo ?? Label} alt={data.name} /></td>
            <td>{data.linked ? <span onClick={() => navigate(`${data.ID}`)} className="link">{data.name}</span> : <span>{data.name}</span>}</td>
            <td><div className="search-desc">{data.description}</div></td>
            <td>{data.url}</td>
            <td>{data.linked ? null : <button onClick={handlerAdd} className="button small">Добавить</button>}</td>
        </tr>
    )
}