import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import Preloader from "../../Preloader";
import { useDispatch, useSelector } from "react-redux";
import annotationPlugin from 'chartjs-plugin-annotation';
import { getMainGraphicData } from "../../../store/ShopsStore/mainGraphic";
import WeekWidgets from "../../ShopWidgets/WeekWidgets";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

const BigGraph = ({code}) => {

    const dispatch = useDispatch()

    const [lineData, setLineData] = useState(null)
    const [type, setType] = useState("count")
    const [step, setStep] = useState("week")
    const [offset, setOffset] = useState(0)

    const labels = useSelector((state) => state.mainGraphic.labels)
    const shops = useSelector((state) => state.mainGraphic.shops)
    const data = useSelector((state) => state.mainGraphic.data)

    useEffect(() => {
        dispatch(getMainGraphicData(step, offset, code))
    }, [dispatch, step, offset, code])

    useEffect(() => {
        if (labels == null || data == null) {
            return
        }

        const typeData = data.find(e => e.code === type)

        if (!typeData) {
            return
        }

        let dataLine = {
            labels : labels,
            datasets: [
                {
                    data: typeData.points,
                    label: typeData.label,
                    fill: false,
                    cubicInterpolationMode: 'monotone',
                    borderColor: "rgb(190, 215, 48)",
                },
                {
                    data: typeData.middle,
                    label: typeData.label + ' middle',
                    fill: false,
                    cubicInterpolationMode: 'monotone',
                    borderColor: "rgb(50, 215, 48)",
                    borderDash: [3, 3],
                }
            ]
        }

        let optionsLine = {
            responsive: true,
            maintainAspectRatio: false,
/*             scales: {
                x: {
                    ticks: {
                        maxRotation: 45,
                        minRotation: 45
                    }
                }
            }, */
            plugins: {
                legend: {
                    position: "top",
                },
            }
        }

        const min = typeData.min ?? 0
        const max = typeData.max ?? 0

        optionsLine.plugins.annotation = {
            annotations: {
                min : {
                    type: 'line',
                    borderColor: '#de401f',
                    borderWidth: 1,
                    borderDash: [3, 3],
                    label: {
                        backgroundColor: 'white',
                        color: '#de401f',
                        content: `Минимум — ${min.toLocaleString('ru-RU')}`,
                        display: true,
                        position: 'start'
                    },
                    scaleID: 'y',
                    value: min
                },
                max : {
                    type: 'line',
                    borderColor: '#21a74e',
                    borderWidth: 1,
                    borderDash: [3, 3],
                    label: {
                        backgroundColor: 'white',
                        color: '#21a74e',
                        content: `Максимум — ${max.toLocaleString('ru-RU')}`,
                        display: true,
                        position: 'start'
                    },
                    scaleID: 'y',
                    value: max
                }
            }
        }

        if (shops) {
            shops.forEach(e => {
                optionsLine.plugins.annotation.annotations[e.code] = {
                    type: 'line',
                    borderColor: '#9c36b5',
                    borderWidth: 1,
                    borderDash: [3, 3],
                    label: {
                      backgroundColor: 'white',
                      color: '#9c36b5',
                      content: `+ ${e.label}`,
                      display: true,
                      position: '50%'
                    },
                    xMin: e.index ?? 0,
                    xMax: e.index ?? 0,
                    display: true
                }
            })
        }

        setLineData({data: dataLine, options: optionsLine})

    }, [labels, data, type, shops])

    const handlerSelector = (s, t) => {
        if (t !== type) {
            setType(t)
        }

        if (s !== step) {
            //setLineData(null)
            //dispatch(resetMainGraph())
            setStep(s)
        }
    }

    const handlerSelectorOffset = (value) => {
        setOffset(offset + value)
    }

    if (!lineData) {
        return (
            <Preloader />
        )
    }

    return (
        <div>
            <Selector handler={handlerSelector} handlerSelectorOffset={handlerSelectorOffset} />
            <div style={{height: 400}}>
                <Line style={{width: '100%'}} data={lineData.data} options={lineData.options} />
            </div>
            <WeekWidgets type={type} />
        </div>
    )
}

export default BigGraph

const Selector = ({handler, handlerSelectorOffset}) => {
    const [s, setS] = useState("week")
    const [t, setT] = useState("count")

    const handlerType = (value) => {
        setT(value)
    }

    const handlerStep = (value) => {
        setS(value)
    }

    useEffect(() => {
        handler(s, t)
    }, [s, t, handler])

    return (
        <div style={{display: 'flex'}}>
            <SelectorType handler={handlerType} type={t} />
            <SelectorStep step={s} handler={handlerStep} />
            {/* <SelectorOffset handler={handlerSelectorOffset} /> */}
        </div>
    )
}

const SelectorStep = ({step, handler}) => {

    const [list, setList] = useState([])

    useEffect(() => {
        const steps = [
            {
                name: 'day',
                label: "По дням"
            },
            {
                name: 'week',
                label: "По неделям"
            },
            {
                name: 'month',
                label: "По месяцам"
            }
        ]

        let tmp = []

        steps.forEach((e, i) => {
            let data = {
                name: e.name,
                label: e.label,
                active: e.name === step
            }

            tmp.push(data)
        })

        setList(tmp)
    }, [step])

    return (
        <div className="button-group" style={{marginRight:20}}>
            {list.map((e, i) => <button className={`button-group-item ${e.active}`} key={i} onClick={() => handler(e.name)}>{e.label}</button>)}
        </div>
    )
}

const SelectorType = ({type, handler}) => {

    const [list, setList] = useState([])

    useEffect(() => {
        const steps = [
            {
                name: 'count',
                label: "Количество продаж"
            },
            {
                name: 'pay',
                label: "Выплаты"
            },
            {
                name: 'margin',
                label: "Маржа"
            }
        ]

        let tmp = []

        steps.forEach((e, i) => {
            let data = {
                name: e.name,
                label: e.label,
                active: e.name === type
            }

            tmp.push(data)
        })

        setList(tmp)
    }, [type])

    return (
        <div className="button-group" style={{marginRight:20}}>
            {list.map((e, i) => <button className={`button-group-item ${e.active}`} key={i} onClick={() => handler(e.name)}>{e.label}</button>)}
        </div>
    )
}

const SelectorOffset = ({handler}) => {
    return (
        <div>
            <button onClick={() => handler(-1)}>L</button>
            <button onClick={() => handler(1)}>R</button>
        </div>
    )
}