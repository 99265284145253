import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { getBrands } from "../../../../store/SettingsStore/brands"
import { useNavigate } from "react-router-dom" 
import Label from "../../../../assets/images/label.svg"
import ModalFS from "../../../../components/Modal/fullScreen"
import SearchBrands from "./Search"
import AddBrand from "./Add"

const BrandsSettings = () => {
    const dispatch = useDispatch()

    const brands = useSelector((state) => state.brands.list)
    const brandAdd = useSelector((state) => state.brands.add)
    const [m, setM] = useState(null)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Бренды'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getBrands())
    }, [dispatch])

    useEffect(() => {
        handlerClose()
    }, [brandAdd])

    const handlerClose = () => {
        setM(null)
    }

    return (
        <>
            <div className="mb-24" style={{display: 'flex'}}>
                <button className="button" 
                        style={{marginRight: 24}} 
                        onClick={() => setM("search")}>Поиск</button>
                <button className="button" 
                        onClick={() => setM("add")}>Добавить</button>
            </div>
            <div className="flex" style={{margin: -10, flexWrap: 'wrap'}}>
                {brands.map(e => <Brand key={e.ID} data={e} />)}
            </div>
            {m ? <ModalFS close={handlerClose} body={<BrandModal type={m} />} /> : null}
        </>
    )
}

export default BrandsSettings

const Brand = ({data}) => {
    const navigate = useNavigate()

    return (
        <div className="brand-block linked" onClick={() => navigate(`${data.ID}`)}>
            <img src={data.logo ?? Label} alt={data.name} />
            <h4>{data.name}</h4>
            <div>{data.description}</div>
        </div>
    )
}

const BrandModal = ({type}) => {
    if (type === "search") {
        return <SearchBrands />
    }
    return <AddBrand />
}