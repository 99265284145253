import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const linker = createSlice({
    name: 'linker',
    initialState: {
        prod: null,
        list: [],
        conn: null
    },
    reducers: {
        setLinkerProd: (state, action) => {
            state.prod = action.payload
        },
        setLinkerList: (state, action) => {
            state.list = action.payload
        },
        setConn: (state, action) => {
            state.conn = action.payload
        }
    }
})

export const { setLinkerProd, setLinkerList, setConn } = linker.actions

export const checkList = (id) => (dispatch, getState) => {
    let tmp = [...getState().linker.list]

    let find = tmp.findIndex(e => e === id)

    if (find < 0) {
        tmp.push(id)
    } else {
        tmp.splice(find, 1)
    }

    dispatch(setLinkerList(tmp))
}

export const connect = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/products/linker`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(setConn(true))
        })
        .catch((error) => {
            dispatch(setConn(false))
        });
}

export default linker.reducer