import { useEffect, useState } from "react"
import Switcher from "../Switcher"

const PricerDetails = ({param, log, handler, access}) => {
    return (
        <div className="flex">
            <div className="pricer-form">
                {access ? <Form param={param} handler={handler} /> : "Лимит исчерпан"}
            </div>
            {log ? 
            <div className="pricer-log">
                <Log param={param} />
            </div> : null }
        </div>
    )
}

export default PricerDetails

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const Form = ({param, handler}) => {
    const [id, setID] = useState(0)
    const [target, setTarget] = useState(0)
    const [min, setMin] = useState(0)
    const [minV, setMinV] = useState(0)
    const [max, setMax] = useState(0)
    const [maxV, setMaxV] = useState(0)
    const [a, setA] = useState(false)
    const [u, setU] = useState(null)

    useEffect(() => {
        if (param) {
            if (param.target) {
                setTarget(param.target)
            }
    
            if (param.minOffset) {
                setMin(param.minOffset)
            }
    
            if (param.maxOffset) {
                setMax(param.maxOffset)
            }
    
            if (param.active) {
                setA(param.active)
            }
    
            if (param.ID) {
                setID(param.ID)
            }
        } else {
            setTarget(0)
            setMin(0)
            setMax(0)
            setA(false)
            setID(0)
        }
        setU(uuidv4())
    }, [param])

    useEffect(() => {
        let t = parseInt(target)
        setMinV(Math.round(t + t * min / 100))
        setMaxV(Math.round(t + t * max / 100))
    }, [min, max, target])

    const handlerSwitch = (e) => {
        setA(e.target.checked)
    }

    const handlerSave = () => {
        handler(
            {
                ID: id,
                target: parseInt(target),
                minOffset: parseFloat(min),
                maxOffset: parseFloat(max),
                active: a
            }
        )
    }

    return (
        <>
            <Switcher active={a} handler={handlerSwitch} label="Активный" />
            <div style={{marginTop: 20}}>
                <div className="form-row">
                    <label htmlFor={`target-${u}`}>
                        <div className="form-label">Целевая цена</div>
                        <input id={`target-${u}`}
                                name="target"
                                type="number"
                                className="text-field"
                                required
                                value={target}
                                onChange={e => setTarget(e.target.value)} />
                    </label>
                </div>
                <div className="form-row-flex">
                    <label htmlFor={`min-${u}`}>
                        <div className="form-label">Нижний порог цены - %</div>
                        <input id={`min-${u}`}
                                name="min"
                                type="number"
                                step="0.1" 
                                className="text-field"
                                required
                                value={min}
                                onChange={e => setMin(e.target.value)} />
                    </label>
                    <span className="form-row-flex-end">{minV}</span>
                </div>
                <div className="form-row-flex" style={{marginBottom: 20}}>
                    <label htmlFor={`max-${u}`}>
                        <div className="form-label">Верхний порог цены - %</div>
                        <input id={`max-${u}`}
                                name="min"
                                type="number"
                                step="0.1"
                                min={min + 0.1} 
                                className="text-field"
                                required
                                value={max}
                                onChange={e => setMax(e.target.value)} />
                    </label>
                    <span className="form-row-flex-end">{maxV}</span>
                </div>
                <div className="form-row">
                    <button onClick={handlerSave} style={{width: '100%'}} className="button">Сохранить</button>
                </div>
            </div>
        </>
    )
}

const Log = ({param}) => {
    return (
        <div>
            <h4 style={{marginTop: 0}}>Log</h4>
        </div>
    )
}