import { createSlice } from '@reduxjs/toolkit'

export const pageTitle = createSlice({
    name: 'pageTitle',
    initialState: {
        title: null,
        subtitle: null,
        back: null,
    },
    reducers: {
        setPageTitleTitle: (state, action) => {
            state.title = action.payload
        },
        setPageTitleSubTitle: (state, action) => {
            state.subtitle = action.payload
        },
        setPageTitleBack: (state, action) => {
            state.back = action.payload
        }
    }
})

export const { setPageTitleTitle, setPageTitleSubTitle, setPageTitleBack } = pageTitle.actions

export default pageTitle.reducer