import { useDispatch, useSelector } from "react-redux"
import BigGraph from "../../../../components/Graph/BigGraph"
import { useEffect, useState } from "react"
import { resetMainGraph } from "../../../../store/ShopsStore/mainGraphic"
import Informator from "../../../../components/Informator"
import FullShopView from "../../../../components/ShopWidgets/FullShopView"
import Donuts from "./Donuts"
import Preloader from "../../../../components/Preloader"
import { getWeekGraphData, setWeekGraphData } from "../../../../store/ShopsStore/weekGraphic"

const FullAppPage = () => {

    const dispatch = useDispatch()

    const systemShops = useSelector((state) => state.systemShops.list)
    const companyShops = useSelector((state) => state.companyShops.list)

    const [graph, setGraph] = useState(false)
    
    useEffect(() => {
        return () => {
            dispatch(resetMainGraph())
            dispatch(setWeekGraphData(null))
        }
    }, [dispatch])

    useEffect(() => {
        companyShops.forEach(s => {
            if (s.data && s.data.days) {
                setGraph(true)
                return
            }
        });
    }, [companyShops])

    useEffect(() => {
        dispatch(getWeekGraphData())
    }, [dispatch])

    if (!systemShops) return (
        <Preloader />
    )
    
    return (
        <>
            <div className="flex" style={{marginBottom: 24}}>
                <div style={{flexGrow: 1}}>
                    {graph ? <BigGraph /> : <Informator message="Сбор данных. Ждём" type="info" />}
                    
                </div>
                <Donuts />
            </div>

            <div className="shop-view-block">
                {systemShops.map((e, i) => <FullShopView key={i} systemShop={e} />)}
            </div>
        </>
    )
}

export default FullAppPage