import { Outlet } from "react-router-dom"
import PageTitle from "../../../components/PageTitle"
import "./style.css"

const ShopPage = () => {
    return (
        <>
            <PageTitle />
            <Outlet />
        </>
    )
}

export default ShopPage