import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getSystemShop } from "../../../../store/ShopsStore/systemShops"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import Preloader from "../../../../components/Preloader"
import { getOzonProduct } from "../../../../store/ShopsStore/oz"
import { getWBProduct } from "../../../../store/ShopsStore/wb"
import ProductSales from "./ProductSales"
import { gatewayURL } from "../../../../store/api"
import { getYmProduct } from "../../../../store/ShopsStore/ym"

const ProductShopPage = () => {

    let {code, id} = useParams()

    const dispatch = useDispatch()

    const systemShops = useSelector((state) => state.systemShops.list)
    const shop = useSelector((state) => state.systemShops.shop)
    const product = useSelector((state) => state[code].product)

    useEffect(() => {
        if (shop === null || shop.code !== code) {
            dispatch(getSystemShop(code))
        }
    }, [shop, code, dispatch, systemShops])

    useEffect(() => {
        if (shop !== null) {
            dispatch(setPageTitleTitle(shop.title))
            dispatch(setPageTitleSubTitle('Товары'))
            dispatch(setPageTitleBack({name: 'Назад', link: `/app/${code}/products`}))
    
        }
    }, [dispatch, shop, code])

    useEffect(() => {
        switch(code) {
            case "oz":
                dispatch(getOzonProduct(id))
                break
            case "wb":
                dispatch(getWBProduct(id))
                break
            case "ym":
                dispatch(getYmProduct(id))
                break;
            default:
                break
        }
    }, [code, id, dispatch])

    if (product === null) {
        return <Preloader />
    }

    return (
        <>
            <div style={{display: "flex"}}>
                <div className="ws-sidebar">
                    <div>
                    <img src={`${gatewayURL}/api/v1/images/${code}/${product.ID}/${product.image}`} alt={product.title} />
                    </div>
                    <div>
                        <h3>{product.title}</h3>
                    </div>
                    <div className="chip-article">
                        {product.article}
                    </div>
                </div>
                <div className="ws-main">
                    <ProductSales />
                </div>
            </div>
        </>
    )
}

export default ProductShopPage