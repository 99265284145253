import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { getDonutGraphicData } from '../../../../../store/ShopsStore/donutGraphic';
import { useEffect, useState } from 'react';
import Preloader from '../../../../../components/Preloader';

ChartJS.register(ArcElement, Tooltip, Legend);

/* export const data = {
  labels: ['WB', 'OZON'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      ]
    },
  ],
};
 */
const Donuts = () => {
    const dispatch = useDispatch()

    const datas = useSelector((state) => state.donutGraphic.data)

    const [p, setP] = useState(null)
    const [m, setM] = useState(null)

    const parseType = (type) => {
        let labels = []
        let percents = []
        let sum = 0
        type.data.forEach(e => {
            let v = e.value ?? 0
            sum = sum + v
        })
        type.data.forEach(e => {
            labels.push(e.label)
            let v = e.value ?? 0
            percents.push((v * 100) / sum)
        })
        return {
            labels,
            percents
        }
    }

    const parseDatas = () => {
        datas.forEach(type => {
            let data = parseType(type)

            let d = {
                labels: data.labels,
                datasets: [
                  {
                    label: '%',
                    data: data.percents ?? 0,
                    backgroundColor: [
                      '#F01A8A',
                      '#005BFF',
                      '#fce000',
                    ]
                  },
                ],
              };

            switch (type.label) {
                case "Pay":
                    setP(d)
                    break
                case "Margin":
                    setM(d)
                    break
                default:
                    return
            }
        });
    }

    useEffect(() => {
        if (datas === null) {            
            dispatch(getDonutGraphicData())
        } else {
            parseDatas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, datas])

    if (!p || !m) {
        return (
            <Preloader />
        )
    }

    return (
        <div style={{flex: '0 0 170px', marginLeft: 24}}>
            <h4 style={{textAlign: 'center', marginBottom: 6}}>Денежный поток</h4>
            <Doughnut data={p} options={{
                plugins: {
                    legend: {
                    display: false,
                    },
                },
            }} />
            <h4 style={{textAlign: 'center', marginBottom: 6}}>Маржа</h4>
            <Doughnut data={m} options={{
                plugins: {
                    legend: {
                    display: false,
                    },
                },
            }} />
        </div>
    )
}

export default Donuts