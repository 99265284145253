import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const PageTitle = () => {

    const title = useSelector((state) => state.pageTitle.title)
    const subtitle = useSelector((state) => state.pageTitle.subtitle)
    const back = useSelector((state) => state.pageTitle.back)

    return (
        <div className="page-title">
            {back !== null ? <Link link={back} /> : null}
            <h1>{title} {subtitle ? `/ ${subtitle}` : null}</h1>
        </div>
    )
}

export default PageTitle

const Link = ({link}) => {
    const navigate = useNavigate()
    return (
            <div className="page-title-back">
                <span className="link" onClick={() => navigate(link.link)}>
                    {link.name}
                </span>
            </div>
    )
}