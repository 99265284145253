import { useEffect, useState } from "react"
import Modal from "../Modal"
import Confirm from "../Confirm"
import ButtonDelete from "../Buttons/button-delete"

const MarginData = ({margin, save, remove, removeRes}) => {
    return (
        <div className="flex">
            <MarginForm data={margin.data} save={save} />
            <MarginHistory data={margin.history} remove={remove} removeRes={removeRes} />
        </div>
    )
}

export default MarginData

const MarginForm = ({data, save}) => {

    const [ID, setID] = useState(null)
    const [min, setMin] = useState(0)
    const [mid, setMid] = useState(0)
    const [max, setMax] = useState(0)
    const [upd, setUpd] = useState(false)

    useEffect(() => {
        if (data !== null) {
            setID(data.ID)
            setMin(data.min)
            setMid(data.mid)
            setMax(data.max)
        }
    }, [data])

    const handlerSubmit = (e) => {
        e.preventDefault();
        save({
            ID: ID,
            min: min,
            mid: mid,
            max: max,
            upd: upd,
        })
    }

    return (
        <div style={{width: 400, marginBottom: 24, marginRight: 24}}>
            <h3>Маржинальность в процентах</h3>
            <form onSubmit={handlerSubmit}>
                <div className="form-row">
                    <label htmlFor="min">
                            <div className="form-label">Критическое значение</div>
                            <input id="min"
                                name="min"
                                type="number"
                                required
                                className="text-field"
                                min={0}
                                value={min}
                                onChange={e => setMin(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="min">
                            <div className="form-label">Удовлетворительное значение</div>
                            <input id="mid"
                                name="mid"
                                type="number"
                                required
                                className="text-field"
                                min={parseInt(min) + 1}
                                value={mid}
                                onChange={e => setMid(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="min">
                            <div className="form-label">Оптимальное значение</div>
                            <input id="max"
                                name="max"
                                type="number"
                                required
                                className="text-field"
                                min={parseInt(mid) + 1}
                                value={max}
                                onChange={e => setMax(e.target.value)} />
                    </label>
                </div>
                {ID ?
                <div className="mb-12">
                    <label htmlFor="update">
                        <input id="update" type="checkbox" checked={upd} onChange={e => setUpd(e.target.checked)} />
                        Изменить текущие 
                    </label>
                </div> : null}
                <div>
                    <button className="button">Сохранить</button>
                </div>
            </form>
        </div>
    )
}

const MarginHistory = ({data, remove, removeRes}) => {

    const [conf, setConf] = useState(null)

    useEffect(() => {
        if (removeRes === "ok") {
            setConf(null)
        }
    }, [removeRes])

    const handlerRemove = (e) => {
        setConf(e)
    }

    const handlerConfirm = () => {
        remove(conf)
    }

    const handlerClose = () => {
        setConf(null)
    }

    if (!data || data.length === 0) {
        return null
    }

    return (
        <div style={{flexGrow: 1, marginBottom: 24}}>
            <h3>История изменений</h3>
            <table className="table-params">
                <thead>
                    <tr>
                        <td>Дата</td>
                        <td>Критическая</td>
                        <td>Удовлетворительная</td>
                        <td>Оптимальная</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                {data.map((e, i) => <MarginHistoryRow key={i} data={e} handler={handlerRemove} />)}
                </tbody>
            </table>
            {conf ? <Modal close={handlerClose} body={<Confirm callbackNo={handlerClose} callbackYes={handlerConfirm} />} /> : null}
        </div>
    )
}

const MarginHistoryRow = ({data, handler}) => {
    
    const handlerButton = () => {
        handler(data.ID)
    }

    return (
        <tr>
            <td>{data.created}</td>
            <td>{data.min}</td>
            <td>{data.mid}</td>
            <td>{data.max}</td>
            <td>
                <ButtonDelete handler={handlerButton} />
            </td>
        </tr>
    )
}