import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const mainGraphic = createSlice({
    name: 'mainGraphic',
    initialState: {
        data: null
    },
    reducers: {
        setWeekGraphData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setWeekGraphData } = mainGraphic.actions

const parseWeekGraphData = (data) => (dispatch, getState) => {

    let d = {}
    data.forEach(element => {
        let l = ""
        switch (element.label) {
            case "Count":
                l = "Количество"
                break
            case "Pay":
                l = "Выплаты"
                break
            case "Margin":
                l = "Маржа"
                break
            case "Marginality":
                l = "Маржтнальность"
                break
            default:
                return
        }
        let c = {
            code: element.label.toLowerCase(),
            label: l,
            min: element.min ?? 0,
            mid: element.mid ?? 0,
            max: element.max ?? 0,
            now: element.value ?? 0
        }
        d[element.label.toLowerCase()] = c
    });

    dispatch(setWeekGraphData(d))
}

export const getWeekGraphData = (code) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let url

    if (code) {
        url = `${gatewayURL}/api/v1/shops/${code}/graph/week`
    } else {
        url = `${gatewayURL}/api/v1/graph/week`
    }

    let config = {
        method: 'get',
        url: url,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(parseWeekGraphData(response.data.result.data))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export default mainGraphic.reducer