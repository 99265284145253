import { useState } from "react"
import { useDispatch } from "react-redux"
import { setCompanyShops } from "../../../store/ShopsStore/companyShops"

const ShopConnectForm = ({shop}) => {
        switch (shop.code) {
        case "oz":
            return <OzonForm />
        case "wb":
            return <WbForm />
        case "ym":
            return <YmForm />
        default:
            return null
    }
}

export default ShopConnectForm

const OzonForm = () => {

    const dispatch = useDispatch()
    
    const [client, setClient] = useState("")
    const [token, setToken] = useState("")

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(setCompanyShops(
            {
                shopCode: "oz",
                authParam: {
                    clientID: client,
                    apiKey: token
                }
            }
        ))
    }

    return (
        <>
            <h2 style={{marginTop: 0}}>Подключение Ozon</h2>
            <form style={{width: 300, marginBottom: 24}} onSubmit={handlerSubmit}>
                    <div className="form-row">
                        <label htmlFor="clientID">
                            <div className="form-label">ClientID</div>
                            <input id="clientID"
                                    name="clientID"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={client}
                                    onChange={e => setClient(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row" style={{marginBottom: 18}}>
                        <label htmlFor="apiKey">
                            <div className="form-label">ApiKey</div>
                            <input id="apiKey"
                                    name="apiKey"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={token}
                                    onChange={e => setToken(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <button style={{width: '100%'}} className="button">Сохранить</button>
                    </div>
                </form>
        </>
    )
}

const WbForm = () => {

    const dispatch = useDispatch()

    const [token, setToken] = useState("")

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(setCompanyShops(
            {
                shopCode: "wb",
                authParam: {
                    token: token
                }
            }
        ))
    }

    return (
        <>
            <h2 style={{marginTop: 0}}>Подключение Wildberries</h2>
            <form style={{width: 300, marginBottom: 24}} onSubmit={handlerSubmit}>
                    <div className="form-row" style={{marginBottom: 18}}>
                        <label htmlFor="token">
                            <div className="form-label">Токен</div>
                            <input id="token"
                                    name="token"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={token}
                                    onChange={e => setToken(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <button style={{width: '100%'}} className="button">Сохранить</button>
                    </div>
                </form>
        </>
    )
}

const YmForm = () => {

    const dispatch = useDispatch()
    
    const [business, setBusiness] = useState("")
    const [token, setToken] = useState("")

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(setCompanyShops(
            {
                shopCode: "ym",
                authParam: {
                    businessID: business,
                    token: token
                }
            }
        ))
    }

    return (
        <>
            <h2 style={{marginTop: 0}}>Подключение YM</h2>
            <form style={{width: 300, marginBottom: 24}} onSubmit={handlerSubmit}>
                    <div className="form-row">
                        <label htmlFor="businessID">
                            <div className="form-label">BusinessID</div>
                            <input id="businessID"
                                    name="businessID"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={business}
                                    onChange={e => setBusiness(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row" style={{marginBottom: 18}}>
                        <label htmlFor="token">
                            <div className="form-label">Token</div>
                            <input id="token"
                                    name="token"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={token}
                                    onChange={e => setToken(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-row">
                        <button style={{width: '100%'}} className="button">Сохранить</button>
                    </div>
                </form>
        </>
    )
}
