const Confirm = ({title, titleYes, titleNo, callbackNo, callbackYes}) => {
    return (
        <div>
            <h3 style={{marginTop: 0}}>{title ?? "Удалить?"}</h3>
            <button style={{marginRight: 20}} className="button cancel" onClick={callbackNo}>{titleNo ?? "Отмена"}</button>
            <button className="button" onClick={callbackYes}>{titleYes ?? "Да"}</button>
        </div>
    )
}

export default Confirm