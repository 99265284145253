import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const prod = createSlice({
    name: 'prod',
    initialState: {
        data: null,
        purchases: null,
        linked: []
    },
    reducers: {
        setProd: (state, action) => {
            state.data = action.payload
        },
        setPurchases: (state, action) => {
            state.purchases = action.payload
        },
        setLinked: (state, action) => {
            state.purchases = action.payload
        }
    }
})

export const { setProd, setPurchases, setLinked } = prod.actions

export const getProd = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setProd(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const updateProd = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/settings/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getProd(id))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const setBrandMyProd = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/products/${id}/brand`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getProd(id))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const resetBrandMyProd = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/settings/products/${id}/brand`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getProd(id))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getProdPurchases = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/products/${id}/purchase`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if(typeof response.data.result.purchases !== 'undefined') {
                dispatch(setPurchases(response.data.result.purchases))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const removePurchase = (id, pid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/settings/products/${id}/purchase`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            ID: pid
        }
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getProd(id))
            dispatch(getProdPurchases(id))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const setPurchaseStore = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/products/${id}/purchase`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getProd(id))
            dispatch(getProdPurchases(id))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const unlinkShopProduct = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/settings/products/linker`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getProd(data.productID))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default prod.reducer