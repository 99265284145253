import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const productSales = createSlice({
    name: 'productSales',
    initialState: {
        sales: null
    },
    reducers: {
        setProductSales: (state, action) => {
            state.sales = action.payload
        }
    }
})

export const { setProductSales } = productSales.actions

export const getProductsSales = (code, ID, f, t) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/${code}/products/${ID}/sales?from=${f}&to=${t}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setProductSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default productSales.reducer