import { useNavigate } from "react-router-dom"
import "./style.css"
import { useEffect, useState } from "react"
import MenuIcon from "./MenuIcon"
import IconSVG from "../../IconSVG"
import { useSelector } from "react-redux"

const Sidebar = () => {

    const navigate = useNavigate()

    const companyShops = useSelector((state) => state.companyShops.list)

    const [o, setO] = useState(false)
    const [s, setS] = useState(false)
    const [shops, setShops] = useState([])

    useEffect(() => {
        let tmp = []
        if (companyShops) {
            companyShops.forEach(e => {
                if (e.data) {
                    if (e.active && e.data.auth && e.data.sales) {
                        tmp.push(e)
                    }
                }
            });
            setShops(tmp)
        }
    }, [companyShops])

    const handlerOpen = () => {
        if (!o) {
            setO(true)
            setTimeout(() => {
                setS(true)
            }, 200)
        } else {
            setS(false)
            setO(false)
        }
    }

    return (
        <div className={`sidebar ${o}`}>
            <div className="sidebar-fix">
                <div className="sidebar-inner">
                    <div className="sidebar-menu">
                        <MenuIcon open={o}
                                  handler={handlerOpen} />
                        <ul>
                            <MenuItem s={s} 
                                      label="Главная"
                                      navigate={() => navigate("/app")}
                                      icon={<IconSVG icon="Home" color="#fff" />} />
                            {shops.map((e, i) => <ShopItem key={i} s={s} shop={e} navigate={navigate} />)}
                            <MenuItem s={s} 
                                      label="Настройки"
                                      navigate={() => navigate("/app/settings")}
                                      icon={<IconSVG icon="Settings" color="#fff" />} />
                            <MenuItem s={s} 
                                      label="На сайт"
                                      navigate={() => navigate("/")}
                                      icon={<IconSVG icon="Web" color="#fff" />} />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar

const MenuItem = ({s, icon, label, navigate}) => {
    return (
        <li>
            <div className="menu-item-inner" onClick={navigate}>
                <div style={{width: 24}}>{icon}</div>
                {s ? <span>{label}</span> : null}
            </div>
        </li>
    )
}

const ShopItem = ({s, shop, navigate}) => {
    return (
        <li>
            <div className="menu-item-inner" onClick={() => navigate(`/app/${shop.shop.code}`)}>
                <div className="menu-item-letter">{shop.shop.title[0]}</div>
                {s ? <span>{shop.shop.title}</span> : null}
            </div>
        </li>
    )
}