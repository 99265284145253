import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import Preloader from "../../../../components/Preloader"
import { useParams } from "react-router-dom"
import { deleteMarginBrand, getBrand, getBrandMargin, getBrandMarginArchive, setBrand, setBrandMargin, setBrandMarginHistory, setMarginBrand, updateMarginBrand } from "../../../../store/SettingsStore/brand"
import MarginData from "../../../../components/MarginData"

const BrandSettings = () => {
    
    let {id} = useParams()

    const dispatch = useDispatch()

    const brand = useSelector((state) => state.brand.brand)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Бренды'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings/brands`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getBrand(id))
    }, [dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setBrand(null))
            dispatch(setBrandMargin(null))
            dispatch(setBrandMarginHistory([]))
        }
    }, [dispatch])

    if (brand === null) {
        return (
            <Preloader />
        )
    }

    return (
        <div>
            <h2>{brand.name}</h2>
            <BrandAccess access={brand.linked} />
            <BrandMargin id={id} />
        </div>
    )
}

export default BrandSettings

const BrandMargin = ({id}) => {

    const dispatch = useDispatch()

    const brandMargin = useSelector((state) => state.brand.margin)
    const historyMargin = useSelector((state) => state.brand.history)
    const removeRes = useSelector((state) => state.brand.removeRes)

    useEffect(() => {
        dispatch(getBrandMargin(id))
        dispatch(getBrandMarginArchive(id))
    }, [dispatch, id])

    const saveHandler = (data) => {
        let send = {
            //marginID: data.ID,
            min: parseInt(data.min),
            mid: parseInt(data.mid),
            max: parseInt(data.max),
            brandID: parseInt(id)
        }
        if (!data.upd) {
            dispatch(setMarginBrand(send))
        } else {
            send.marginID = data.ID
            dispatch(updateMarginBrand(send))
        }
    }

    const removeHandler = (data) => {
        dispatch(deleteMarginBrand(
            {
                marginID: data
            }, id
        ))
    }

/*     if (brandMargin === null) {
        return (
            <Preloader />
        )
    } */

    return <MarginData margin={{data: brandMargin, history: historyMargin}} save={saveHandler} remove={removeHandler} removeRes={removeRes} />
}

const BrandAccess = ({access}) => {
    return (
        <div>
            <h3 style={{color: '#f90'}}>Доступ к данным {access ? "предоставлен" : "закрыт"}</h3>
        </div>
    )
}