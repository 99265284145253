import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const saleDetails = createSlice({
    name: 'saleDetails',
    initialState: {
        data: null,
        details: null
    },
    reducers: {
        setSaleData: (state, action) => {
            state.data = action.payload
        },
        setSaleDetails: (state, action) => {
            state.details = action.payload
        }
    }
})

export const { setSaleData, setSaleDetails } = saleDetails.actions

export const getSaleDetails = (code, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/${code}/sales/${data.ID}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            final : data.final ?? false,
            article: data.item.article ?? "",
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setSaleDetails(response.data.result.details))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default saleDetails.reducer