import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { setProductsParam } from './productsParams';
axios.defaults.withCredentials = true;

export const ym = createSlice({
    name: 'ym',
    initialState: {
        widgetSales: null,
        widgetCount: null,
        products: null,
        product: null,
        unsortedCount: null,
        unsortedList: null,
        sales: null
    },
    reducers: {
        setYmWidgetSales: (state, action) => {
            state.widgetSales = action.payload
        },
        setYmWidgetCount: (state, action) => {
            state.widgetCount = action.payload
        },
        setYmProducts: (state, action) => {
            state.products = action.payload
        },
        setYmProduct: (state, action) => {
            state.product = action.payload
        },
        setYmUnsortedCount: (state, action) => {
            state.unsortedCount = action.payload
        },
        setYmUnsortedList: (state, action) => {
            state.unsortedList = action.payload
        },
        setYmSales: (state, action) => {
            state.sales = action.payload
        }
    }
})

export const { setYmWidgetSales, setYmWidgetCount, setYmProducts, setYmProduct, setYmUnsortedCount, setYmUnsortedList, setYmSales } = ym.actions

export const getYmWidgetSales = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/ym/widgets/sales`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setYmWidgetSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getYmWidgetCount = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/ym/widgets/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setYmWidgetCount(response.data.result.count))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getYmProducts = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/ym/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.products !== 'undefined') {
                dispatch(setYmProducts(response.data.result.products))
            } else {
                dispatch(setYmProducts([]))
            }

            if (typeof response.data.result.pagination !== 'undefined') {
                dispatch(setProductsParam('ym', 'pagination', response.data.result.pagination))
            } 
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getYmProduct = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/ym/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setYmProduct(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getYmUnsortedCount = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/ym/unsorted/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setYmUnsortedCount(response.data.result.count))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getYmUnsortedList = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/ym/unsorted/list`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.products!== 'undefined') {
                dispatch(setYmUnsortedList(response.data.result.products))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getYmSales = (from, to) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/ym/sales`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from,
            to
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setYmSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default ym.reducer