import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSystemShops } from "../../../../store/ShopsStore/systemShops"
import { getCompanyShops } from "../../../../store/ShopsStore/companyShops"
import Preloader from "../../../../components/Preloader"
import FullAppPage from "../FullAppPage"
import StartAppPage from "../StartAppPage"

const AppPage = () => {
    
    const dispatch = useDispatch()

    const systemShops = useSelector((state) => state.systemShops.list)
    const systemShopsLoad = useSelector((state) => state.systemShops.loadList)
    const companyShops = useSelector((state) => state.companyShops.list)
    const companyShopsLoad = useSelector((state) => state.companyShops.loadList)

    useEffect(() => {
        dispatch(getSystemShops())
        dispatch(getCompanyShops())
    }, [dispatch])

    useEffect(() => {

    }, [systemShops, companyShops])

    if (!systemShopsLoad && !companyShopsLoad) {
        return <Preloader />
    }

    if (!companyShops) {
        return (
            <StartAppPage />
        )
    }

    return <FullAppPage />
}

export default AppPage