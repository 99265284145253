import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const donutGraphic = createSlice({
    name: 'donutGraphic',
    initialState: {
        data: null
    },
    reducers: {
        setDonutData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setDonutData } = donutGraphic.actions

export const getDonutGraphicData = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let url = `${gatewayURL}/api/v1/graph/donuts`

    let config = {
        method: 'get',
        url: url,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setDonutData(response.data.result.data))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export default donutGraphic.reducer