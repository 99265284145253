import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthComponent from "./components/Auth";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Web";
import { Provider } from "react-redux";
import store from "./store/store";
import LoginPage from "./pages/Auth/Login";
import RegistrationPage from "./pages/Auth/Registration";
import ResetPassPage from "./pages/Auth/Reset";
import AppPage from "./pages/App/AppPage/Start";
import FullShopPage from "./pages/App/ShopPage/FullShopPage";
import ProductsShopPage from "./pages/App/ShopPage/ProductsShopPage";
import ProductShopPage from "./pages/App/ShopPage/ProductShopPage";
import ShopPage from "./pages/App/ShopPage";
import SettingsPage from "./pages/App/SettingsPage";
import MainSettings from "./pages/App/SettingsPage/MainSettings";
import MarginSettings from "./pages/App/SettingsPage/MarginSetting";
import TaxSettings from "./pages/App/SettingsPage/TaxSetting";
import BrandsSettings from "./pages/App/SettingsPage/BrandsSettings";
import BrandSettings from "./pages/App/SettingsPage/BrandSettings";
import ProductsSettingsList from "./pages/App/SettingsPage/ProductsSettings/ProductsSettingsList";
import ProductSettings from "./pages/App/SettingsPage/ProductsSettings/ProductSettings";
import Linker from "./pages/App/SettingsPage/ProductsSettings/Linker";
import SalesPage from "./pages/App/ShopPage/SalesPage";
import ProfileSettings from "./pages/App/SettingsPage/ProfileSettings";
import Messages from "./components/Messages";
import ServicesSettings from "./pages/App/SettingsPage/ServicesSettings";
import ServiceSettings from "./pages/App/SettingsPage/ServiceSettings";

const Layout = () => {
    return (
        <AuthComponent>
            <Outlet />
        </AuthComponent>
    )
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/register',
        element: <RegistrationPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/reset-password',
        element: <ResetPassPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/app',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path:'',
                element: <AppPage />
            },
            {
                path:':code',
                element: <ShopPage />,
                children: [
                    {
                        path:'',
                        element: <FullShopPage />
                    },
                    {
                        path:'products',
                        children: [
                            {
                                path: '',
                                element: <ProductsShopPage />,
                            },
                            {
                                path:':id',
                                element: <ProductShopPage />
                            }        
                        ]
                    },
                    {
                        path:'sales',
                        element: <SalesPage />
                    },
                ]
            },
            {
                path:'settings',
                element: <SettingsPage />,
                children: [
                    {
                        path:'',
                        element: <MainSettings />
                    },
                    {
                        path:'profile',
                        element: <ProfileSettings />
                    },
                    {
                        path:'margin',
                        element: <MarginSettings />
                    },
                    {
                        path:'tax',
                        element: <TaxSettings />
                    },
                    {
                        path:'brands',
                        children: [
                            {
                                path: '',
                                element: <BrandsSettings />
                            },
                            {
                                path: ':id',
                                element: <BrandSettings />
                            }
                        ]
                    },
                    {
                        path:'products',
                        children: [
                            {
                                path: '',
                                element: <ProductsSettingsList />
                            },
                            {
                                path: ':id',
                                element: <ProductSettings />
                            },
                            {
                                path: 'linker/:code',
                                element: <Linker />
                            }
                        ]
                    },
                    {
                        path:'services',
                        children: [
                            {
                                path: '',
                                element: <ServicesSettings />
                            },
                            {
                                path: ':id',
                                element: <ServiceSettings />
                            }
                        ]
                    }
                ]
            }
        ]
    }
])

function App() {
    return (
        <Provider store={store}>
            <RouterProvider router={router} />
            <Messages/>
        </Provider>
    );
}

export default App;
