import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { getCompanyShops } from './companyShops'

export const systemShops = createSlice({
    name: 'systemShops',
    initialState: {
        list: null,
        shop: null,
        loadList: false
    },
    reducers: {
        setShopsList: (state, action) => {
            state.list = action.payload
        },
        setShopsShop: (state, action) => {
            state.shop = action.payload
        },
        setLoadListSys: (state, action) => {
            state.loadList = action.payload
        }
    }
})

export const { setShopsList, setShopsShop, setLoadListSys } = systemShops.actions

export const getSystemShops = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/all`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setShopsList(response.data.result.shops))
            dispatch(setLoadListSys(true))
        })
        .catch((error) => {
            dispatch(setLoadListSys(true))
        });
}

export const getSystemShop = (code) => (dispatch, getState) => {
    let list = getState().systemShops.list

    if (list === null) {
        dispatch(getSystemShops())
    } else {
        let findShop = list.find(s => s.code === code)
        if (findShop) {
            dispatch(setShopsShop(findShop))
            dispatch(getCompanyShops())
        }
    }
}

export default systemShops.reducer