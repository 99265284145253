import { useEffect, useState } from "react"
import { getServices } from "../../../../store/SettingsStore/services"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useParams } from "react-router-dom"
import Preloader from "../../../../components/Preloader"
import PricerSettings from "./PricerSettings"

const ServiceSettings = () => {

    const {id} = useParams()

    const [serv, setServ] = useState(null)

    const dispatch = useDispatch()

    const services = useSelector((state) => state.services.list)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Сервисы'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings/services`}))

        if (!services || services.length === 0) {
            dispatch(getServices())
        } else {
            let find = services.findIndex(e => e.service.ID === parseInt(id))
            if (find > -1) {
                setServ(services[find])
            }
        }
    }, [dispatch, services, id])

    if (!serv) {
        return <Preloader />
    }

    return (
        <>
            <h2>{serv.service.title}</h2>
            {serv.service.code === 'pwb' || serv.service.code === 'poz' || serv.service.code === 'pym' ? <PricerSettings service={serv} /> : null}
        </>
    )
}

export default ServiceSettings