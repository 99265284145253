import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const mainGraphic = createSlice({
    name: 'mainGraphic',
    initialState: {
        labels: null,
        shops: null,
        data: null
    },
    reducers: {
        setGraphLabs: (state, action) => {
            state.labels = action.payload
        },
        setGraphShops: (state, action) => {
            state.shops = action.payload
        },
        setGraphData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setGraphLabs, setGraphShops, setGraphData } = mainGraphic.actions

export const convertGraphLabs = (labels) =>(dispatch) => {
    let tmp = []
    labels.forEach((e, i) => {
        /* let n = e.replaceAll("2023", '23')
        n = n.replaceAll("2024", '24')
        tmp.push(n) */
        let n = e.split("-")[0]
        n = n.replaceAll("2023", '23')
        n = n.replaceAll("2024", '24')
/*         if (labels.length > 20 && i % 2 === 0) {
            n = ""
        } */
        tmp.push(n)
    });
    dispatch(setGraphLabs(tmp))
}

export const resetMainGraph = () =>(dispatch) => {
    dispatch(setGraphLabs(null))
    dispatch(setGraphShops(null))
    dispatch(setGraphData(null))
}

export const getMainGraphicData = (step, offset, code) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let url

    if (code) {
        url = `${gatewayURL}/api/v1/shops/${code}/graph/main`
    } else {
        url = `${gatewayURL}/api/v1/graph/main`
    }

    let config = {
        method: 'post',
        url: url,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            step,
            offset
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(convertGraphLabs(response.data.result.labels))
                dispatch(setGraphShops(response.data.result.shops))
                dispatch(setGraphData(response.data.result.data))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export default mainGraphic.reducer