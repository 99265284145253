import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPricerData, getPricerParams, setPricer, setPricerParams, updatePricerParams } from "../../../../../store/SettingsStore/services"
import Preloader from "../../../../../components/Preloader"
import "../style.css"
import { gatewayURL } from "../../../../../store/api"
import PricerDetails from "../../../../../components/PricerDetails"

const PricerSettings = ({service}) => {

    const dispatch = useDispatch()

    const pricer = useSelector((state) => state.services.pricer)
    const pricerParams = useSelector((state) => state.services.pricerParams)

    useEffect(() => {
        if (service.access) {
            dispatch(getPricerData(service.service.code))
        }
    }, [service, dispatch, pricerParams])

    useEffect(() => {
        return () => {
            dispatch(setPricer())
            dispatch(setPricerParams())
        }
    }, [dispatch])

    if (!service.access) {
        return (
            <>
                Не подключен
            </>
        )
    }
    
    return (
        <>
            { pricer ? <PricerSettingsData data={pricer} service={service} /> : null }
            { pricer ? <PricerParams code={pricer.shopCode} /> : null }
        </>
    )
}

export default PricerSettings

const PricerSettingsData = ({data, service}) => {
    return (
        <div className="service-datas">
            <div className="service-data">
                <h5>Сервис доступен до</h5>
                <div>{service.dateEnd}</div>
            </div>
            <div className="service-data">
                <h5>Доступно товаров</h5>
                <div>{data.limit - (data.used ?? 0)} из {data.limit} </div>
            </div>
        </div>
    )
}

const PricerParams = ({code}) => {

    const dispatch = useDispatch()

    const pricerParams = useSelector((state) => state.services.pricerParams)

    useEffect(() => {
        dispatch(getPricerParams(code))
    }, [dispatch, code])

    if (!pricerParams) {
        return <Preloader />
    }

    return (
        <div className="pricer">
            {pricerParams.map((e, i) => <Param key={i} param={e} code={code} />)}
        </div>
    )
}

const Param = ({param, code}) => {

    const dispatch = useDispatch()

    const [image, setImage] = useState(null)
    const [s, setS] = useState(false)

    useEffect(() => {
        if (param.product.preview) {
            let imgData = param.product.preview.split("/")
            setImage(`${gatewayURL}/api/v1/images/${imgData[0]}/${imgData[2]}/${imgData[3]}`)
        }
    }, [param.product.preview])

    const handlerSend = (data) => {
        dispatch(updatePricerParams(data, code))
    }

    return (
        <div className={`pricer-block ${s ? "active" : ""}`}>
            <div className="pricer-block-img">
                <div className={`pricer-indicator ${param.active ? "active" : ""}`}></div>
                <img src={image} alt={param.product.title} />
            </div>
            <div className="pricer-block-content">
                <h4>{param.product.title}</h4>
                <div className="mb-24" onClick={() => setS(!s)}><span className="link">{s ? "Свернуть" : "Открыть"}</span></div>
                {s ? <PricerDetails param={param} handler={handlerSend} access={true} log={true} /> : null}
            </div>
        </div>
    )
}
