import { useState } from "react"
import Logo from "../../../assets/images/logo-full.svg"
import View from "../../../assets/images/view-svgrepo-com.svg"
import Hide from "../../../assets/images/view-hide-svgrepo-com.svg"
import "../style.css"
import { useNavigate } from "react-router-dom"
import { registerStore } from "../../../store/AuthStore/auth"
import { useDispatch } from "react-redux"
import { updateMessagesList } from "../../../store/System/messages"

const ViewIcon = () => {
    return (
        <img src={View} alt="Password" />
    )
}

const HideIcon = () => {
    return (
        <img src={Hide} alt="Password" />
    )
}

const RegistrationPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [company, setCompany] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [pass, setPass] = useState("")
    const [cPass, setCPass] = useState("")
    const [passV, setPassV] = useState(false)
    const [cPassV, setCPassV] = useState(false)
    
    const handlerSubmit = (e) => {
        e.preventDefault();
        if (pass !== cPass) {
            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: "Error Conf Pass",
                    type: "error",
                    time: t.getTime()
                }
            ))
        } else {
            dispatch(registerStore({company, name, email, phone, password: pass}, navigate))
        }
    }

    return (
        <div className="page-center-align-v">
            <div className="page-center-align">
                <div>
                    <span onClick={() => navigate("/")} className="link">Back to web</span>
                </div>

                <img src={Logo}
                    style={{width: 300}}
                    alt="Cerasus" />
                
                <h1>Регистрация</h1>

                <form style={{width: 300, marginBottom: 24}} onSubmit={handlerSubmit}>
                    <div className="form-row">
                        <label htmlFor="company">
                            <div className="form-label">Компания</div>
                            <input id="company"
                                    name="company"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={company}
                                    onChange={e => setCompany(e.target.value)} />
                        </label>
                    </div>

                    <div className="form-row">
                        <label htmlFor="name">
                            <div className="form-label">Имя</div>
                            <input id="name"
                                    name="name"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)} />
                        </label>
                    </div>

                    <div className="form-row">
                        <label htmlFor="email">
                            <div className="form-label">Эл. почта</div>
                            <input id="email"
                                    name="email"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />
                        </label>
                    </div>

                    <div className="form-row">
                        <label htmlFor="phone">
                            <div className="form-label">Телефон</div>
                            <input id="phone"
                                    name="phone"
                                    type="text"
                                    className="text-field"
                                    required
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)} />
                        </label>
                    </div>

                    <div className="form-row">
                        <label htmlFor="password" className="password-block">
                            <div className="form-label">Пароль</div>
                            <input id="password"
                                    name="password"
                                    type={passV ? "text" : "password"}
                                    required
                                    autoComplete="off"
                                    value={pass}
                                    className="text-field"
                                    onChange={e => setPass(e.target.value)} />
                            <span className="hide-show-password" onClick={() => setPassV(!passV)}>
                                {passV ? <HideIcon /> : <ViewIcon />}
                            </span>
                        </label>
                    </div>

                    <div className="form-row" style={{marginBottom: 18}}>
                        <label htmlFor="confirm_password">
                            <div className="form-label">Пароль еще раз</div>
                            <input id="confirm_password"
                                    name="confirm_password"
                                    type={cPassV ? "text" : "password"}
                                    required
                                    autoComplete="off"
                                    value={cPass}
                                    className="text-field"
                                    onChange={e => setCPass(e.target.value)} />
                            <span className="hide-show-password" onClick={() => setCPassV(!cPassV)}>
                                {cPassV ? <HideIcon /> : <ViewIcon />}
                            </span>
                        </label>
                    </div>
                    <div className="form-row">
                        <button style={{width: '100%'}} className="button">Регистрация</button>
                    </div>
                </form>

                <div>
                    <span onClick={() => navigate("/login")} className="link">Войти</span>
                </div>
            </div>
        </div>
    )
}

export default RegistrationPage