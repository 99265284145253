import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Preloader from "../../../../../components/Preloader"
import { getSaleDetails, setSaleData, setSaleDetails } from "../../../../../store/ShopsStore/saleDetails"
import SalesListComp from "../../../../../components/SalesList/salesList"

const SalesList = () => {

    const {code} = useParams()

    const dispatch = useDispatch()

    const sales = useSelector((state) => state[code].sales)

    const handlerOpen = (data) => {
        dispatch(setSaleData(data))
        dispatch(getSaleDetails(code, data))
    }

    const handlerClose = () => {
        dispatch(setSaleData(null))
        dispatch(setSaleDetails(null))
    }

    if (!sales) {
        return (
            <Preloader />
        )
    }

    return (
        <SalesListComp sales={sales} open={handlerOpen} close={handlerClose} titleCol={true} />
    )
}

export default SalesList