import { useEffect, useState } from "react"
import ModalFS from "../../../../../components/Modal/fullScreen"
import { useDispatch, useSelector } from "react-redux"
import { getSearch, setSearchData, setSearchRequest } from "../../../../../store/SettingsStore/search"
import ProdListItem from "../ProductsSettingsList/ListItem"

const SearchProducts = () => {
    
    const dispatch = useDispatch()
    const search = useSelector((state) => state.search.request)

    const closeHandler = () => {
        dispatch(setSearchRequest(""))
        dispatch(setSearchData([]))
    }

    return (
        <>
            <FormSearch num={1} />
            {search !== "" ? <ModalFS body={<SearchModal />} close={closeHandler}  /> : null}
        </>
    )
}

export default SearchProducts

const FormSearch = ({num}) => {

    const dispatch = useDispatch()
    const search = useSelector((state) => state.search.request)

    const [s, setS] = useState(search)

    useEffect(() => {
        if (search === "") {
            setS("")
        }
    }, [search])

    const handler = () => {
        if (s.trim() !== "" && s.length > 2) {
            dispatch(setSearchRequest(s))
        }
    }

    return (
        <div className="mb-24" style={{marginTop: 24}}>
            <div className="mb-12">
                <label htmlFor={`searchfield-${num}`}>
                    <div className="form-label">Поиск</div>
                    <input id={`searchfield-${num}`}
                        name="searchfield"
                        type="text"
                        required
                        className="text-field"
                        value={s}
                        onChange={e => setS(e.target.value)} />
                </label>
            </div>
            <div>
                <button onClick={handler} className="button">Найти</button>
            </div>
        </div>
    )
}

const SearchModal = () => {

    const dispatch = useDispatch()

    const request = useSelector((state) => state.search.request)
    const data = useSelector((state) => state.search.data)

    useEffect(() => {
        if (request.length > 2) {
            dispatch(getSearch(request))
        }
    }, [request, dispatch])

    return (
        <div>
            <h2>Поиск: «{request}». Результатов — {data.length}</h2>
            <FormSearch num={2} />
            {data.length > 0 ? <SearchResults /> : <div>Нет результатов</div>}
        </div>
    )
}

const SearchResults = () => {
    const data = useSelector((state) => state.search.data)

    return(
       <div>
           <div className="list-view">
                {data.map((e,i) => <ProdListItem key={i} data={e} view={false} />)}
            </div>
       </div>
   )
}