import { useNavigate } from "react-router-dom"
import Logo from "../../assets/images/logo-full.svg"
import Gra from "../../assets/images/main-people.svg"

const HomePage = () => {
    const navigate = useNavigate()

    return (
        <div style={{maxWidth: 1200, width: '70%', margin: '0 auto'}}>
            <div style={{marginBottom: 20, marginTop:20, width: '30%'}}>
                <img onClick={() => navigate("/app")} 
                                src={Logo} 
                                className="header-logo" 
                                alt="Cerasus" />
            </div>
            <div style={{display: 'flex'}}>
                <div style={{flex: '0 0 50%'}}>
                    <h1 style={{fontSize: 50}}>Узнайте, сколько вы заработали торгуя на маркетплейсах</h1>
                    <div>Автоматизированная система построения управленческой отчетности для маркетплейсов. Система позволит быстро определить основные показатели и увидеть их в динамике. Управленческая отчетность (аналитика) Wildberries, OZON — теперь это просто и доступно!</div>
                    <button style={{width: '90%', marginTop:40}} onClick={() => navigate("/app")} className="button">Войти</button>
                </div>
                <div style={{flex: '0 0 50%'}}>
                    <img onClick={() => navigate("/app")} 
                            src={Gra} 
                            alt="Cerasus" />
                </div>
            </div>

           
        </div>
    )
}

export default HomePage