import ShopWidgetRows from "../ShopWidgetRows"

const SalesWidgetShop = ({code}) => {
    return (
        <div className="widget-block" style={{paddingTop: 3}}>
            <ShopWidgetRows code={code} links={true} />
        </div>
    )
}

export default SalesWidgetShop