import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logOut } from "../../../../store/AuthStore/auth"

const UserMenu = ({close}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handlerLogOut = () => {
        dispatch(logOut())
    }

    return (
        <div className="user-menu" onMouseLeave={close}>
            <ul>
                <li onClick={() => navigate('/app/settings/profile')}>
                    <span>Профиль</span>
                </li>
                <li onClick={handlerLogOut}>
                    <span>Выйти</span>
                </li>
            </ul>
        </div>
    )
}

export default UserMenu