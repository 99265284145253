import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"
import "../style.css"

const Pagination = ({code}) => {

    const dispatch = useDispatch()

    const pagination = useSelector((state) => state.productsParams[code].pagination)

    if (!pagination.pages || pagination.pages < 2) {
        return null
    }

    const handlerPage = (page) => {
        let np = {...pagination}
        np.page = page
        dispatch(setProductsParam(code, 'pagination', np))
        dispatch(setProductsParam(code, 'load', true))
    }

    return (
        <div className="pagination">
            <ul>
                {[...Array(pagination.pages)].map((e, i) => <Item key={i} i={i+1} page={pagination.page} handler={handlerPage} />)}
            </ul>
        </div>
    )
}

export default Pagination

const Item = ({i, page, handler}) => {
    return (
        <li onClick={() => handler(i)} className={i === page ? "active" : ""}>{i}</li>
    )
}