import Logo from "../../../assets/images/logo-full.svg"
import { useNavigate } from "react-router-dom"
import "./style.css"
import AlarmsHeaderWrapper from "./Alarms"
import UserHeaderWrapper from "./User"

const HeaderWrapper = () => {
    
    const navigate = useNavigate()

    return (
        <div className="header">
            <div className="header-logo">
                <img onClick={() => navigate("/app")} 
                     src={Logo} 
                     className="header-logo" 
                     alt="Cerasus" />
            </div>
            <div className="header-right">
                <AlarmsHeaderWrapper />
                <UserHeaderWrapper />
            </div>
        </div>
    )
}

export default HeaderWrapper