import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {  getProductPricerData, getServices } from "../../../../../store/SettingsStore/services"
import Preloader from "../../../../../components/Preloader"
import PricerProduct from "./Pricer"
import CounterProduct from "./Counter"

const ServicesBlock = ({product}) => {
    const dispatch = useDispatch()

    const services = useSelector((state) => state.services.list)

    const [s, setS] = useState(null)

    useEffect(() => {
        dispatch(getServices())
    }, [dispatch])

    useEffect(() => {
        let tmp = []

        services.forEach(service => {
            if (!service.access || !service.service || !service.service.active) {
                return
            }

            let find = -1

            switch (service.service.code) {
                case "pwb":
                    find = product.shopProductLinks.findIndex(e => e.shopCode === "wb")
                    if (find > -1) {
                        tmp.push(service.service)
                    }
                    break
                case "poz":
                    find = product.shopProductLinks.findIndex(e => e.shopCode === "oz")
                    if (find > -1) {
                        tmp.push(service.service)
                    }
                    break
                case "pym":
                    find = product.shopProductLinks.findIndex(e => e.shopCode === "ym")
                    if (find > -1) {
                        tmp.push(service.service)
                    }
                    break
                case "cntr":
                    tmp.push(service.service)
                    break
                default:
                    break
            }
        });
        setS(tmp)
    }, [services, product.shopProductLinks])

    if (!s) {
        return (
            <Preloader />
        )
    }
    
    return (
        <>
            {s.map((e, i) => <Service key={i} data={e} product={product} />)}
        </>
    )
}

export default ServicesBlock

const Service = ({data, product}) => {
    if (data.code === 'pwb' || data.code === 'poz' || data.code === 'pym') {
        return <Pricers data={data} product={product} />
    }

    if (data.code === 'cntr') {
        return <CounterProduct product={product} />
    }

    return null
}

const Pricers = ({data, product}) => {

    const dispatch = useDispatch()

    const [p, setP] = useState(null)

    const productPricers = useSelector((state) => state.services.productPricers)

    useEffect(() => {
        if (!productPricers) {
            dispatch(getProductPricerData())
        } else {
            let code = data.code.substr(1)

            let find = productPricers.findIndex(e => e.shopCode === code)

            if (find > -1) {
                setP(productPricers[find])
            }
        }
    }, [dispatch, productPricers, data.code])

    if (!p) {
        return null
    }

    return (
        <PricerProduct pricer={p} product={product} />
    )
}