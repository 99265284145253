import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCounterProduct, setCounterProduct } from "../../../../../store/SettingsStore/services"
import CounterDetails from "../../../../../components/CounterDetails"

const CounterProduct = ({product}) => {
    
    const dispatch = useDispatch()

    const counterProduct = useSelector((state) => state.services.counterProduct)

    useEffect(() => {
        dispatch(getCounterProduct(product.ID))
    }, [product.ID, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setCounterProduct(null))
        }
    }, [dispatch])

    return (
        <div className="simple-block service" >
            <h3>Управление остатками</h3>
            <CounterDetails data={counterProduct} productID={product.ID} />
        </div>
    )
}

export default CounterProduct