import { useState } from "react"
import Logo from "../../../assets/images/logo.svg"
import "../style.css"
import { useNavigate } from "react-router-dom"

const ResetPassPage = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    
    const handlerSubmit = (e) => {
        e.preventDefault();
        console.log(email, "code")
    }

    return (
        <div>
            <div>
                <span onClick={() => navigate("/")} className="link">Back to web</span>
            </div>

            <img src={Logo} className="login-logo" alt="Cerasus" />

            <h1>Reset Password</h1>

            <form onSubmit={handlerSubmit}>
                <div>
                    <label htmlFor="email">
                        Email
                        <input id="email"
                                name="email"
                                type="text"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                    </label>
                </div>
                <div>
                    <button>Send</button>
                </div>
            </form>

            <div>
                <span onClick={() => navigate("/login")} className="link">Login</span>
            </div>
        </div>
    )
}

export default ResetPassPage