import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const brands = createSlice({
    name: 'brands',
    initialState: {
        list: [],
        search: [],
        add: false
    },
    reducers: {
        setBrandsList: (state, action) => {
            state.list = action.payload
        },
        setSearchFindBrands: (state, action) => {
            state.search = action.payload
        },
        setAddBrand: (state, action) => {
            state.add = action.payload
        },
    }
})

export const { setBrandsList, setSearchFindBrands, setAddBrand } = brands.actions

export const getBrands = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/brands?page=1`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.brands !== 'undefined') {
                dispatch(setBrandsList(response.data.result.brands))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const serachBrands = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/brands/search`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.brands) {
                dispatch(setSearchFindBrands(response.data.result.brands))
            } else {
                dispatch(setSearchFindBrands([]))
            }
        })
        .catch((error) => {
            dispatch(setSearchFindBrands([]))
            console.log(error);
        });
}

export const setCompanyBrand = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/brands`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getBrands())
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const addCompanyBrand = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/brands`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()
    
    axios.request(config)
        .then((response) => {
            dispatch(setAddBrand(true))
            dispatch(getBrands())
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default brands.reducer