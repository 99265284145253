import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"
import { useEffect, useState } from "react"

const FilterProductsBrand = ({code, brands}) => {

    const dispatch = useDispatch()

    const selectedBrands = useSelector((state) => state.productsParams[code].brands)

    const handlerAll = () => {
        dispatch(setProductsParam(code, 'brands', []))
    }

    if (brands === null || brands.length === 0) {
        return null
    }
    
    return (
        <div className="blocker">
            <h4>Бренды</h4>
            <div style={{marginBottom: 3}}>
                <label htmlFor="allCheck">
                    <input 
                        id="allCheck" 
                        checked={selectedBrands.length === 0}
                        onChange={() => handlerAll()} 
                        type="checkbox"/>
                    Все
                </label>
            </div>

            {brands.map((e, i) => <BrandRow key={i} data={e} code={code} />)}
        </div>
    )
}

export default FilterProductsBrand


const BrandRow = ({data, code}) => {

    const dispatch = useDispatch()

    const selectedBrands = useSelector((state) => state.productsParams[code].brands)

    const handlerBrand = (id) => {
        let tmp = []
        
        const find = selectedBrands.findIndex(e => e === id)
        if (find === -1) {
            tmp.push(id)
        }

        selectedBrands.forEach(e => {
            if (e === id) {
                return
            } else {
                tmp.push(e)
            }
        });

        dispatch(setProductsParam(code, 'brands', tmp))
    }

    const [s, setS] = useState(false)
    useEffect(() => {
        const find = selectedBrands.findIndex(e => e === data.ID)
        setS(find !== -1)
    }, [data, selectedBrands])
    return (
        <div style={{marginBottom: 3}}>
            <label htmlFor={`brid_${data.ID}`}>
                <input 
                    id={`brid_${data.ID}`} 
                    checked={s}
                    onChange={() => handlerBrand(data.ID)} 
                    type="checkbox"/>
                {data.name}
            </label>
        </div>
    )
}