import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"
import Sort from "../../../assets/images/sort.svg"

const ViewTypeProducts = ({code}) => {

    const dispatch = useDispatch()

    const view = useSelector((state) => state.productsParams[code].view)

    return (
        <div>
            <div className="sorting-data" onClick={() => dispatch(setProductsParam(code, 'view', !view))} >
                Размер <SortIcon psName={view} />
            </div>
        </div>
    )
}

export default ViewTypeProducts

const SortIcon = ({psName}) => {
    return (
        <div style={{
            backgroundImage: `url(${Sort})`, 
            backgroundSize: 'cover', 
            width: 14, 
            height: 14, 
            marginLeft: 6,
            transform: psName ? 'rotate(180deg)' : 'rotate(0deg)'
        }}></div>
    )
}