import IconSVG from "../../../IconSVG"

const AlarmsHeaderWrapper = () => {
    return (
        <div className="flex" style={{alignItems: 'center'}}>
            <div className="mr-10">
                <IconSVG icon="Alert" color="#ccc" />
            </div>
            <div className="mr-20">
                <IconSVG icon="Message" color="#ccc" />
            </div>
        </div>
    )
}

export default AlarmsHeaderWrapper