import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { serachBrands, setSearchFindBrands } from "../../../../../store/SettingsStore/brands"
import SearchRes from "./SearchRes"

const SearchBrands = () => {
    const dispatch = useDispatch()

    const brands = useSelector((state) => state.brands.list)

    const [s, setS] = useState("")
    const [all, setAll] = useState(false)

    useEffect(() => {
        if (s.length > 2) {
            dispatch(serachBrands(
                {
                    name: s,
                    all: all
                }
            ))
        }
    }, [s, dispatch, all, brands])

    useEffect(() => {
        return () => {
            dispatch(setSearchFindBrands([]))
        }
    }, [dispatch])
    
    return (
        <div>
            <h3>Поиск</h3>
            <div className="form-row">
                <label htmlFor="search">
                    <input id="search"
                            name="search"
                            type="text"
                            className="text-field"
                            required
                            value={s}
                            onChange={e => setS(e.target.value)} />
                </label>
            </div>
            <div className="form-row">
                <label htmlFor="activeCheck">
                    <input 
                        id="activeCheck" 
                        checked={!all}
                        onChange={() => setAll(!all)} 
                        type="checkbox"/>
                    Только в моих брендах
                </label>
            </div>
            <div>
                <SearchRes />
            </div>
        </div>
    )
}

export default SearchBrands

