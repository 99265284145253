const Switcher = ({active, handler, label}) => {    
    return (
        <div className="switcher">
            <label>
                <div className="switch">
                    <input type="checkbox" checked={active} onChange={e => handler(e)} />
                    <span className="slider round"></span>
                </div>
                <span>{label}</span>
            </label>
        </div>
    )
}

export default Switcher