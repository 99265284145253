import { useEffect } from "react"
import "./style.css"
import Cross from "../../assets/images/cross.svg"

const Modal = ({close, body}) => {

    useEffect(() => {
        const b = document.body;
        b.classList.add('noscroll');
    }, [])

    useEffect(() => {
        return () => {
            const b = document.body;
            b.classList.remove('noscroll');
        }
    }, [])

    return (
        <div className="modal-wrapper">
            <div className="modal-body">
                <div onClick={close} 
                         className="modal-close" 
                         style={{backgroundImage: `url(${Cross})`}}>
                </div>
                <div className="modal-body-in">
                    {body}
                </div>
            </div>
        </div>
    )
}

export default Modal