import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { gatewayURL } from "../../../../../store/api"
import { useNavigate } from "react-router-dom"

const ProdListItem = ({data, view, code}) => {

    const [image, setImage] = useState(null)
    const [shops, setShops] = useState(null)
    const [brand, setBrand] = useState(null)

    const brands = useSelector((state) => state.brands.list)

    //console.log(data)

    useEffect(() => {
        if (data.preview) {
            let imgData = data.preview.split("/")
            setImage(`${gatewayURL}/api/v1/images/${imgData[0]}/${imgData[2]}/${imgData[3]}`)
        }
    }, [data.preview])

    useEffect(() => {
        if (data.brandID && brands.length > 0) {
            const find = brands.findIndex(e => e.ID === data.brandID)
            if (find > -1) {
                setBrand(brands[find].name)
            }
        } else {
            setBrand(null)
        }
    }, [data.brandID, brands])

    useEffect(() => {
        if (!shops) {
            let tmp = []
            if (data.shopProductLinks && data.shopProductLinks.length > 0) {
                data.shopProductLinks.forEach(e => {
                    if (!tmp.find(i => i === e.shopCode)) {
                        tmp.push(e.shopCode)
                    }
                });
            }
            setShops(tmp)
        }

    }, [data.shopProductLinks, shops])

    const navigate = useNavigate()

    if (view) {
        return (
            <div className="view-small" onClick={() => navigate(`${data.ID}`)}>
                <div className="view-small-image">
                    <img src={image} alt={data.title} />
                </div>
                <div className="view-small-inner">
                    <div className="view-small-title">{data.title}</div>     
                    <div className="view-small-purchase">{data.purchase} ₽</div>
                    {shops ? <ShopsChip shops={shops} classN="view-small-shops"  /> : null}
                    {brand ? <BrandChip name={brand} classN="view-small-brand" /> : null}          
                </div>
            </div>
        )        
    }

    return (
        <div className="view-big" onClick={() => navigate(`${data.ID}`)}>
            <div className="view-big-title">{data.title}</div>
            <div className="view-big-image">
                {shops ? <ShopsChip shops={shops} classN="view-big-shops" /> : null}
                <div className="view-big-purchase">{data.purchase} ₽</div>
                {brand ? <BrandChip name={brand} classN="view-big-brand" /> : null}  
                <img src={image} alt={data.title} />
                
            </div>
        </div>
    )
}

const ShopsChip = ({shops, classN}) => {
    return (
        <div className={classN}>
            {shops.map((e, i) => <div className="view-chip-shops" key={i}>{e}</div>)}
        </div>
    )
}

const BrandChip = ({name, classN}) => {
    return (
        <div className={classN}>
            {name}
        </div>
    )
}

export default ProdListItem