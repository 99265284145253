import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const messages = createSlice({
    name: 'messages',
    initialState: {
        list: [{message: 'Message', type: "error", time: 1000}, {message: 'Message 2', type: "error", time: 2000}]
    },
    reducers: {
        setMessagesList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setMessagesList } = messages.actions

export const updateMessagesList = (message) => (dispatch, getState) => {
    let tmp = [...getState().messages.list]
    tmp.push(message)
    dispatch(setMessagesList(tmp))
}

export const clearMessagesList = (data) => (dispatch, getState) => {
    let tmp = []
    getState().messages.list.forEach((e, i) => {
        if (e.time !== data.time) {
            tmp.push(e)
        }
    });
    dispatch(setMessagesList(tmp))
}

export default messages.reducer