import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../../store/NavStore/title"
import { getProd, getProdPurchases, removePurchase, resetBrandMyProd, setBrandMyProd, setProd, setPurchaseStore, setPurchases, updateProd } from "../../../../../store/SettingsStore/prod"
import Preloader from "../../../../../components/Preloader"
import { getBrands } from "../../../../../store/SettingsStore/brands"
import Modal from "../../../../../components/Modal"
import { gatewayURL } from "../../../../../store/api"
import { getCompanyShops } from "../../../../../store/ShopsStore/companyShops"
import Switcher from "../../../../../components/Switcher"
import ButtonDelete from "../../../../../components/Buttons/button-delete"
import LinkedBlock from "./linkedBlock"
import ServicesBlock from "./ServicesBlock"

const ProductSettings = () => {
    const {id} = useParams()

    const dispatch = useDispatch()

    const product = useSelector((state) => state.prod.data)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Товары'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings/products`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getProd(id))
        dispatch(getProdPurchases(id))
    }, [dispatch, id])

    const companyShops = useSelector((state) => state.companyShops.list)

    useEffect(() => {
        if (companyShops == null) {
            dispatch(getCompanyShops())
        }
    }, [dispatch, companyShops])

    useEffect(() => {
        return () => {
            dispatch(setProd(null))
            dispatch(setPurchases(null))
        }
    }, [dispatch])

    if (product === null) {
        return (
            <Preloader />
        )
    }

    return (
        <>
            <TitleBlock product={product} />
            
            <div className="product-title">
                <PurchaseBlock product={product} />
                <LinkedBlock product={product} />
            </div>
            {product.shopProductLinks ?
            <div className="services-block">
                <ServicesBlock product={product} />
            </div> : null }
        </>
    )
}

export default ProductSettings

const TitleBlock = ({product}) => {

    const dispatch = useDispatch()

    const [active, setActive] = useState(true)
    const [title, setTitle] = useState("")
    const [comment, setComment] = useState("")

    const [image, setImage] = useState(null)

    useEffect(() => {
        if (product.preview) {
            let imgData = product.preview.split("/")
            setImage(`${gatewayURL}/api/v1/images/${imgData[0]}/${imgData[2]}/${imgData[3]}`)
        }
    }, [product.preview])

    useEffect(() => {
        setActive(product.active ?? false)
        setTitle(product.title)
        setComment(product.comment ?? "")
    }, [product])

    const handlerSubmit = (e) => {
        e.preventDefault()
        let data = {
            title,
            comment,
            active
        }
        dispatch(updateProd(product.ID, data))
    }

    const handlerSwitch = (e) => {
        setActive(e.target.checked)
    }

    return (
        <div className="product-title">
            <div className="product-title-image">
                <img src={image} alt={product.title} />
            </div>
            <div className="product-title-form" style={{flexGrow: 1}}>
                <div className="mb-12">
                    <Switcher active={active} handler={handlerSwitch} label="Активный" />
                </div>

                <div className="mb-24">
                    <form onSubmit={handlerSubmit}>
                        <div className="form-row">
                            <label htmlFor="title">
                                <div className="form-label">Название</div>
                                <input id="title"
                                        name="title"
                                        type="text"
                                        className="text-field"
                                        required
                                        value={title}
                                        onChange={e => setTitle(e.target.value)} />
                            </label>
                        </div>

                        <div className="form-row mb-24">
                            <label htmlFor="comment">
                                <div className="form-label">Комментарий</div>
                                <textarea id="comment"
                                        name="comment"
                                        type="text"
                                        className="text-field"
                                        value={comment}
                                        onChange={e => setComment(e.target.value)} />
                            </label>
                        </div>
                        <div>
                            <button className="button">Сохранить</button>
                        </div>
                    </form>
                </div>
                <BrandBlock product={product} />
            </div>
        </div>
    )
}

const BrandBlock = ({product}) => {

    const dispatch = useDispatch()

    const [BID, setBID] = useState(null)
    const [load, setLoad] = useState(false)
    const [brand, setBrand] = useState(null)
    const [modal, setModal] = useState(false)

    const brands = useSelector((state) => state.brands.list)

    useEffect(() => {
        if (product.brandID) {
            setBID(product.brandID)
            setModal(false)
        } else {
            setBID(null)
            setBrand(null)
            setModal(false)
        }
    }, [product])

    useEffect(() => {
        if (brands.length === 0 && load === false) {
            dispatch(getBrands())
            setLoad(true)
        } 
        
        if (brands.length > 0) {
            const find = brands.find(e => e.ID === BID)

            if (find) {
                setBrand(find)
            }
        }
    }, [dispatch, load, brands, BID])

    const handlerClose = () => {
        setModal(false)
    }

    if (load && brands.length === 0) {
        return null
    }

    return (
        <>
            <div className="block">
                <span className="mr-10">Бренд</span>
                <span className="link" onClick={() => setModal(true)}>
                    {!brand ? "Установить" : brand.name}
                </span>
            </div>
            {modal ? <Modal close={handlerClose} body={<BrandSelector product={product} />} /> : null}
        </>
    )
}

const BrandSelector = ({product}) => {

    const dispatch = useDispatch()

    const brands = useSelector((state) => state.brands.list)

    const handlerBrand = (brandID) => {
        dispatch(setBrandMyProd(product.ID, {brandID}))
    }

    const handlerResetBrand = (brandID) => {
        dispatch(resetBrandMyProd(product.ID, {brandID}))
    }

    return (
        <div style={{width: 300}}>
            <h3 style={{marginTop: 0}}>Выбрать бренд</h3>
            <div style={{maxHeight: 200, overflowY: 'auto', marginBottom: 24}}>
                {brands.map(b => <BrandRow key={b.ID} brand={b} handler={handlerBrand} />)}
            </div>
            <button className="button" onClick={() => handlerResetBrand(product.brandID)}>Сбросить</button>
        </div>
    )
}

const BrandRow = ({brand, handler}) => {
    return (
        <div onClick={() => handler(brand.ID)} style={{marginBottom: 10, paddingBottom: 5, borderBottom: '1px solid #dedede', cursor: "pointer"}}>
            {brand.name}
        </div>
    )
}

const PurchaseBlock = ({product}) => {

    const dispatch = useDispatch()

    const handlerSubmit = (e) => {
        e.preventDefault()
        dispatch(setPurchaseStore(product.ID, {purchase: parseFloat(purchase), dateFrom: ft}))
    }

    const [purchase, setPurchase] = useState("")
    const [ft, setFt] = useState("")

    return (
        <div className="product-title-image">
            <div className="simple-block">
                <h3>Себестоимость</h3>
                <p>Текущая {product.purchase ? `${product.purchase.toLocaleString('ru-RU')} ₽` : "не установлена"}</p>
                <div className="mb-24">
                    <form onSubmit={handlerSubmit}>
                        <div className="form-row">
                            <label htmlFor="purchase">
                                <div className="form-label">Новое значение</div>
                                <input id="purchase"
                                        name="purchase"
                                        className="text-field"
                                        type="number"
                                        required
                                        value={purchase}
                                        onChange={e => setPurchase(e.target.value)} />
                            </label>
                        </div>
                        <div className="form-row mb-24">
                            <label htmlFor="purchase">
                                <div className="form-label">От какой даты</div>
                                <input id="ft"
                                        name="ft"
                                        type="date"
                                        className="date-field"
                                        required
                                        value={ft}
                                        onChange={e => setFt(e.target.value)} />
                            </label>
                        </div>
                        <div>
                            <button className="button">Сохранить</button>
                        </div>
                    </form>
                </div>
                <PurchaseHistoryBlock />
            </div>
        </div>
    )
}

const PurchaseHistoryBlock = () => {

    const purchases = useSelector((state) => state.prod.purchases)

    const [s, setS] = useState(false)

    if (purchases === null) return null

    const Rows = () => {
        return (
            <div style={{paddingTop: 12}}>
                {purchases.map((e,i) => <PurchaseHistoryRow data={e} key={i} />)}
            </div>
        )
    }

    return (
        <div className="mb-12">
            <span className="link" onClick={() => setS(!s)}>История изменений</span>
            {s ? <Rows /> : null}
        </div>
    )
}

const PurchaseHistoryRow = ({data}) => {
    const dispatch = useDispatch()
    
    let {id} = useParams()

    const handlerRemove = () => {
        dispatch(removePurchase(id, data.purchaseID))
    }

    return (
        <div className="flex-row">
            {data.purchase} от {data.dateFrom} <ButtonDelete handler={handlerRemove} />
        </div>
    )
}