import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { setProductsParam } from './productsParams';
axios.defaults.withCredentials = true;

export const oz = createSlice({
    name: 'oz',
    initialState: {
        widgetSales: null,
        widgetCount: null,
        products: null,
        product: null,
        unsortedCount: null,
        unsortedList: null,
        sales: null
    },
    reducers: {
        setOzonWidgetSales: (state, action) => {
            state.widgetSales = action.payload
        },
        setOzonWidgetCount: (state, action) => {
            state.widgetCount = action.payload
        },
        setOzonProducts: (state, action) => {
            state.products = action.payload
        },
        setOzonProduct: (state, action) => {
            state.product = action.payload
        },
        setOzonUnsortedCount: (state, action) => {
            state.unsortedCount = action.payload
        },
        setOzonUnsortedList: (state, action) => {
            state.unsortedList = action.payload
        },
        setOzonSales: (state, action) => {
            state.sales = action.payload
        }
    }
})

export const { setOzonWidgetSales, setOzonWidgetCount, setOzonProducts, setOzonProduct, setOzonUnsortedCount, setOzonUnsortedList, setOzonSales } = oz.actions

export const getOzonWidgetSales = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/oz/widgets/sales`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setOzonWidgetSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOzonWidgetCount = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/oz/widgets/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setOzonWidgetCount(response.data.result.count))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOzonProducts = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/oz/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.products !== 'undefined') {
                dispatch(setOzonProducts(response.data.result.products))
            } else {
                dispatch(setOzonProducts([]))
            }

            if (typeof response.data.result.pagination !== 'undefined') {
                dispatch(setProductsParam('oz', 'pagination', response.data.result.pagination))
            } 
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOzonProduct = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/oz/products/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setOzonProduct(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOzonUnsortedCount = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/oz/unsorted/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setOzonUnsortedCount(response.data.result.count))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOzonUnsortedList = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/shops/oz/unsorted/list`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.products!== 'undefined') {
                dispatch(setOzonUnsortedList(response.data.result.products))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOzonSales = (from, to) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/shops/oz/sales`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from,
            to
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setOzonSales(response.data.result.sales))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default oz.reducer