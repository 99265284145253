import { useSelector } from "react-redux"
import StartShopView from "../../../../components/ShopWidgets/StartShopView"

const StartAppPage = () => {

    const systemShops = useSelector((state) => state.systemShops.list)

    if (!systemShops) return null

    return (
        <div>
            <h1 style={{marginTop: 0, textAlign: 'center'}}>Доступные маркетплейсы</h1>
            <div className="shop-view-block">
                {systemShops.map((e, i) => <StartShopView key={i} data={e} button="Подключить" msg="Уже скоро..." />)}
            </div>
        </div>
        
    )
}

export default StartAppPage