const MenuIcon = ({open, handler}) => {
    return (
        <div className="icon-menu" onClick={handler}>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <rect className={`icon-menu-top ${open}`} x="5" y="5" rx="1" ry="1" width="14" height="2" fill="#fff" />
                <rect className={`icon-menu-middle-1 ${open}`} x="5" y="11" rx="1" ry="1" width="14" height="2" fill="#fff" />
                <rect className={`icon-menu-middle-2 ${open}`} x="5" y="11" rx="1" ry="1" width="14" height="2" fill="#fff" />
                <rect className={`icon-menu-bottom ${open}`} x="5" y="17" rx="1" ry="1" width="14" height="2" fill="#fff" />
            </svg>
        </div>
    )
}

export default MenuIcon