import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../../store/NavStore/title"
import SalesWidgetShop from "../../../../../components/ShopWidgets/SalesWidgetShop"
import ProductsCountWidgetShop from "../../../../../components/ShopWidgets/ProductsCountWidgetShop"
import BigGraph from "../../../../../components/Graph/BigGraph"
import { resetMainGraph } from "../../../../../store/ShopsStore/mainGraphic"

const MainShopView = ({shop}) => {
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitleTitle(shop.title))
        dispatch(setPageTitleSubTitle(null))
        dispatch(setPageTitleBack({name: 'Назад', link: '/app'}))
    }, [dispatch, shop])

    useEffect(() => {
        return () => dispatch(resetMainGraph())
    }, [dispatch])

    return (
        <div className="main-shop-page">
            <div className="main-shop-page-left">
                <ProductsCountWidgetShop code={shop.code} />
                <SalesWidgetShop code={shop.code} />
            </div>
            <div className="main-shop-page-right">
                <BigGraph code={shop.code} />
            </div>
        </div>
    )
}

export default MainShopView