import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../../store/SettingsStore/profile";

const UserSettings = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user)

    const [name, setName] = useState(user.name);
    const [mail, setMail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [password, setPassword] = useState('');

    const handlerSubmit = (e) => {
        e.preventDefault();
        dispatch(updateUserProfile(user.ID, 
            {
                name: name,
                email: mail,
                phone: phone,
                password: password,
                active: true
            }, true
        ))
    }

    return (
        <div style={{maxWidth: 500}}>
            <h3>Настройки профиля</h3>
            <form onSubmit={handlerSubmit}>
                <div className="form-row">
                    <label htmlFor="name">
                            <div className="form-label">Ваше имя</div>
                            <input id="name"
                                name="name"
                                type="text"
                                required
                                className="text-field"
                                value={name}
                                onChange={e => setName(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="mail">
                            <div className="form-label">Электронная почта</div>
                            <input id="mail"
                                name="mail"
                                type="email"
                                required
                                className="text-field"
                                value={mail}
                                onChange={e => setMail(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="phone">
                            <div className="form-label">Телефон</div>
                            <input id="phone"
                                name="phone"
                                type="tel"
                                required
                                className="text-field"
                                value={phone}
                                onChange={e => setPhone(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="password">
                            <div className="form-label">Новый пароль</div>
                            <input id="password"
                                name="password"
                                type="tex"
                                className="text-field"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                    </label>
                </div>
                <div>
                    <button className="button">Сохранить</button>
                </div>
            </form>
        </div>
    )
}

export default UserSettings;