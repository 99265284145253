import { useDispatch, useSelector } from "react-redux"
import { setProductsParam } from "../../../store/ShopsStore/productsParams"
import Sort from "../../../assets/images/sort.svg"

const SortingProducts = ({code}) => {

    const dispatch = useDispatch()

    const psName = useSelector((state) => state.productsParams[code].psName)

    const handler = () => {
        dispatch(setProductsParam(code, 'psName', !psName))
        dispatch(setProductsParam(code, 'load', true))
    }

    return (
        <div>
            <div className="sorting-data" onClick={handler} >
                Название <SortIcon psName={psName} />
            </div>
        </div>
    )
}

export default SortingProducts

const SortIcon = ({psName}) => {
    return (
        <div style={{
            backgroundImage: `url(${Sort})`, 
            backgroundSize: 'cover', 
            width: 14, 
            height: 14, 
            marginLeft: 6,
            transform: psName ? 'rotate(180deg)' : 'rotate(0deg)'
        }}></div>
    )
}