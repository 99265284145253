import { useSelector } from "react-redux"
import StartShopView from "../StartShopView"
import { useEffect, useState } from "react"
import { gatewayURL } from "../../../store/api"
import ShopWidgetRows from "../ShopWidgetRows"
import { useNavigate } from "react-router-dom"

const FullShopView = ({systemShop}) => {
    
    const companyShops = useSelector((state) => state.companyShops.list)

    const [shop, setShop] = useState(null)

    useEffect(() => {
        companyShops.forEach(s => {
            if (s.shop.ID === systemShop.ID) {
                setShop(s)
                return
            }
        });
    }, [companyShops, systemShop])

    if (shop && shop.data) {
        if (!shop.data.auth) {
            return <StartShopView data={systemShop} button="Подключить" msg="Уже скоро..." />
        } else if (!shop.data.sales) {
            return <ShopView data={systemShop} msg="Сбор данных..." />
        } else {
            return <ShopViewSales data={systemShop} />
        }
    }

    return <StartShopView data={systemShop} button="Подключить" msg="Уже скоро..." />
}

export default FullShopView

const ShopView = ({data, msg}) => {
    return (
        <>
            <div className="shop-view">
                <div className="logo-view image">
                    <img src={`${gatewayURL}/api/v1/images/settings/shops/${data.logo}`} alt={data.title} />
                </div>
                <div className="shop-view-actives">
                    <div style={{textAlign: 'center'}}>{msg}</div>
                </div>
            </div>
        </>
    )
}

const ShopViewSales = ({data}) => {

    const navigate = useNavigate()

    return (
        <>
            <div className="shop-view" style={{cursor: 'pointer'}} onClick={() => navigate(data.code)}>
                <div className="logo-view image">
                    <img src={`${gatewayURL}/api/v1/images/settings/shops/${data.logo}`} alt={data.title} />
                </div>
                <ShopWidgetRows code={data.code} />
            </div>
        </>
    )
}

