import { createSlice } from "@reduxjs/toolkit"
import { gatewayURL } from "../api"
import axios from "axios"
import { updateMessagesList } from "../System/messages"

export const margin = createSlice({
    name: 'margin',
    initialState: {
        data: null,
        history: [],
        removeRes: null
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setHistory: (state, action) => {
            state.history = action.payload
        },
        setRemoveRes: (state, action) => {
            state.removeRes = action.payload
        },
    }
})

export const { setData, setHistory, setRemoveRes } = margin.actions

export const getMargin = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setRemoveRes(null))
            dispatch(setData(response.data.result))
        })
        .catch((error) => {
            console.log(error.data)
        });
}

export const setMargin = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getMargin())
            dispatch(getMargins())
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const updateMargin = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    const t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getMargin())
            dispatch(getMargins())
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const deleteMargin = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(setRemoveRes("ok"))
            dispatch(getMargin())
            dispatch(getMargins())
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getMargins = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/margin/archive`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setHistory(response.data.result.margins))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default margin.reducer