import { useEffect, useState } from "react"
import "../style.css"
import { useSelector } from "react-redux"

const WeekWidgets = ({type}) => {

    const data = useSelector((state) => state.weekGraphic.data)

    const [d, setD] = useState(null)

    useEffect(() => {
        if (data) {
            setD(data[type])
        }
    }, [type, data])

    return (
        <div>
            <WeekWidget data={d} />
        </div>
    )
}

export default WeekWidgets

const WeekWidget = ({data}) => {

    const [min, setMin] = useState(0)
    const [mid, setMid] = useState(50)
    const [max, setMax] = useState(100)
    const [now, setNow] = useState(50)
    const [color, setColor] = useState(null)

    const [minV, setMinV] = useState("")
    const [maxV, setMaxV] = useState("")
    const [midV, setMidV] = useState("")
    const [nowV, setNowV] = useState("")

    useEffect(() => {
        if (data !== null) {
            let start = 0;
            let end = 0;
    
            if (data.now < data.min) {
                start = data.now
            } else {
                start = data.min
            }
    
            if (data.now > data.max) {
                end = data.now
            } else {
                end = data.max
            }
    
            let pp = (end - start) / 100
    
            setMin((data.min - start) / pp)
            setMid((data.mid - start) / pp)
            setMax((data.max - start) / pp)
            setNow((data.now - start) / pp)

            if (data.now < data.max) {
                setColor("mid-color")
            }
            if (data.now < data.mid) {
                setColor("min-color")
            }
            if (data.now > data.max) {
                setColor("max-color")
            }

            if (data.max > 1000) {
                setMaxV(`${Math.round(data.max / 1000)} K`)
            } else {
                setMaxV(Math.round(data.max))
            }
            if (data.mid > 1000) {
                setMidV(`${Math.round(data.mid / 1000)} K`)
            } else {
                setMidV(Math.round(data.mid))
            }
            if (data.min > 1000) {
                setMinV(`${Math.round(data.min / 1000)} K`)
            } else {
                setMinV(Math.round(data.min))
            }
            if (data.now > 1000) {
                setNowV(`${Math.round(data.now / 1000)} K`)
            } else {
                setNowV(Math.round(data.now))
            }
        }
    }, [data])

    if (data === null) {
        return null
    }

    return (
        <div className={`week-widget`}>
            <h4>{data.label}</h4>
            <div className="week-widget-numbers">
                <div className="week-widget-number min-color" style={{left: `${min}%`}}>Мин - {minV}</div>
                <div className="week-widget-number mid-color" style={{left: `${mid}%`}}>В среднем - {midV}</div>
                <div className="week-widget-number max-color" style={{left: `${max}%`}}>Макс - {maxV}</div>
                <div className={`week-widget-now ${color}`} style={{left: `${now}%`}}>{nowV}</div>
            </div>
            <div className={`week-widget-graph ${color}`}>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
                <span className={`${color}`}/>
            </div>
           {/*  <div style={{color: '#fff', fontSize: 12, marginTop: 20}}>* за последние 6 недель</div> */}
        </div>
    )
}